import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";

export const Resetpass = () => {

     //Yup Validation start

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    token: Yup.string().required("Required"),
    password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
  c_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    'Must match "password" field value'
  ),
   
  });

  //Yup Validation start

  //forgot API Fetching start

  const Navigate = useNavigate();

  const reset = async (V) => {
    try {
      const response = await axios.post("/reset", V);
      const { message, status } = response.data;
      if (status == true) {
        // setSession(token);
        swal({
          title: "Good job!",
          text: "Your password reset successfully",
          icon: "success",
          button: "Ok",
        });
        Navigate("/userlogin");
      } else {
        Navigate("/reset");
      }
    } catch (error) {
      console.log(error?.message);
      swal({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };

  //forgot API Fetching end

  //protected routing start

  //   const token = localStorage.getItem("accesstoken");
  //   useEffect(()=>{
  //     console.log(token)
  // if(token){
  //  Navigate("/");
  // }else{
  //   Navigate("/userlogin")
  // }
  //   },[])

  //protected routing end

    return (
        <section className="bg-login">
          <Authheader />
          <Formik
            initialValues={{
              email: "",
              token: "",
              password: "",
              c_password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              reset(values);
            }}
          >
            {({ errors, touched }) => (
                <div className="container-fluid pt-5 mb-signup">
              <Form>
                  <div className="row">
                    <div className="offset-lg-3 col-lg-6">
                      <div className="card shadow border-radious bg-card">
                        <div className="card-header">
                          <h4 className="text-light fw-bolder text-center">
                            Welcome To Reset Password
                          </h4>
                        </div>
                        <div className=" card-body">
                        <div className="row">
                          <div className="col-lg-6">
                          <label className="form-label fw-bolder fs-6 text-white">
                            Email
                          </label>
                          <Field
                            name="email"
                            type="email"
                            className="col-lg-6 form-control mb-1"
                            placeholder="Enter Your Email"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                          </div>
                          <div className="col-lg-6">
                          <label className="form-label fw-bolder fs-6 text-white">
                            Token
                          </label>
                          <Field
                            name="token"
                            type="text"
                            className="form-control mb-1"
                            placeholder="Enter Your Token"
                          />
                          {errors.token && touched.token ? (
                            <div className="text-danger">{errors.token}</div>
                          ) : null}
                          </div>
                          <div className="col-lg-6">
                          <label className="form-label fw-bolder fs-6 text-white">
                            password
                          </label>
                          <Field
                            name="password"
                            type="password"
                            className="col-lg-6 form-control mb-1"
                            placeholder="Enter Your password"
                          />
                          {errors.password && touched.password ? (
                            <div className="text-danger">{errors.password}</div>
                          ) : null}
                          </div>
                        <div className="col-lg-6">
                          <label className="form-label fw-bolder fs-6 text-white">
                            Confirm Password
                          </label>
                          <Field
                            name="c_password"
                            type="password"
                            className="col-lg-6 form-control mb-1"
                            placeholder="Enter Your confirm password"
                          />
                          {errors.c_password && touched.c_password ? (
                            <div className="text-danger">{errors.c_password}</div>
                          ) : null}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-light offset-lg-3 col-lg-6 mt-2"
                          >
                            Reset
                          </button>
    
                          {/* <div className="col-lg-12 text-center mt-2">
                            <p className="text-light">
                              If You Don't Have Account ?
                              <Link to="/usersignup" className="text-white">
                                Sign Up
                              </Link>
                            </p>
                          </div> */}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </Form>
                </div>
            )}
          </Formik>
    
          <Authfooter />
        </section>
      );

}
export default Resetpass;
