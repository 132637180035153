import React, { useEffect, useState } from "react";
import Listing from "./Listing";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utlilites/axios";
import Addlisting from "./Addlisting";

export const Hostdashboard = () => {
  //states
  const [data, setData] = useState(null);

  // const token = localStorage.getItem("accesstoken");
  // const listing = () => {
  //   var config = {
  //     method: "get",
  //     url: "http://flexrentaladmin.sasstechnologies.com/api/host/listning",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setData(response.data);
  //       console.log(response.data.listning, "working");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   listing();
  // }, []);

  const Navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/landlordlogin");
  };

  //   useEffect(()=>{
  //     console.log(token)
  // if(token){
  //   console.log("logged in")
  // }else{
  //   Navigate('/landlordlogin')
  // }
  //   },[]);

  return (
    <>
      <div className="h-100vh">
      <Listing />
      </div>
    </>
  );
};

export default Hostdashboard;
