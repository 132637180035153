import React, { useRef, useContext } from "react";
import ReactDOM, { Link, useNavigate } from "react-router-dom";
import "../pages/Custom.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SEARCH } from "../context/Searchcontext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DetailSearch() {
  const date = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { update } = useContext(SEARCH);
  const ref = useRef();
  const refs = useRef();

  const [APIData, setAPIData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [counter1, setCounter1] = useState(0);
  const [checkin, setCheckIn] = useState("");
  const [checkout, setCheckOut] = useState("");
  // const [api, setApi] = useState("");
  // setGuest(counter1)
  console.log(counter1);

  const navigate = useNavigate();

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      console.log(searchValue);
      const filteredData = APIData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      console.log(filteredData, "filter");
      setFilteredResults(filteredData);
    } else {
      setFilteredResults("");
    }
  };
  const search = () => {
    try {
      const filter = new FormData();

      filter.append("byAddress", searchInput);
      filter.append("byCheckin", " ");
      filter.append("byCheckout", " ");
      filter.append("byNoOfGuest", counter1);

      console.log(filter, "search ok");

      var config = {
        method: "POST",
        url: `https://flexrentaladmin.sasstechnologies.com/api/search/listning`,
        data: filter,
        headers: {
          Accept: "application/json",
        },
      };
      axios(config).then(function (response) {
        // setApi(response.data.listnings);
        console.log(response.data.listnings, "response");
        update(response.data.listnings);

        navigate("/allproperty");
      });
    } catch (errors) {}
  };

  window.onload = function () {
    //from ww  w . j  a  va2s. c  o  m
    var today = new Date().toISOString().split("T")[0];
    document.getElementsByName("setTodaysDate")[0].setAttribute("min", today);
    document.getElementsByName("setCheckoutDate")[0].setAttribute("min", today);
  };

  return (
    <>
      <div className="container">
        <div className="row">
              {/* <label className="text-white p-3">Search Location</label> */}
          <div className="col-md-4 p-0 mb">
            <div className="detailsearchbar border border-dark border-5 ">
              <input
                className="search_input ps-4"
                type="text"
                name=""
                placeholder="Search Locations"
                onChange={(e) => searchItems(e.target.value)}
              />

              {/* SERACHING MAP  */}
              <div className="row front pos">
                <div className="col-md-12 ">
                  <div className="scroll">
                    {filteredResults.length !== 0
                      ? filteredResults.map((item) => {
                          return (
                            <Link className="">
                              <div className="card cardi">
                                <div className="result">
                                  <div className="result_input">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h5 className="card-name mt-2">
                                          &nbsp;
                                          {item.property_type}
                                        </h5>
                                      </div>
                                      <div className="col-md-6">
                                        <h6 className="text-end mt-2">
                                          &nbsp;
                                          {item?.address}
                                          &nbsp;
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })
                      : // : ( <div className="card res cardi ">
                        //      <div className="result">
                        //        <div className="result_input">
                        //          <div className="row">
                        //            <div className="col-md-6">
                        //              <h5 className="card-name mt-2">no item found</h5>
                        //            </div>

                        //            <div className="col-md-6">
                        //              <h6 className="text-end mt-2">no item found</h6>
                        //            </div>
                        //          </div>
                        //        </div>
                        //      </div>
                        //    </div>)
                        null}
                  </div>
                </div>
              </div>
            </div>
            {/* SERACHING MAP  */}
          </div>
          <div className="col-md-2 p-0 mb">
          {/* <label></label> */}
            <div className="detailsearchbar border border-dark border-5">
              {/* <input
                className="search_input rounded-0 ps-2 pb-3"
                type="text"
                placeholder="Check-In-Date"
                ref={ref}
                name="setTodaysDate"
                onChange={(e) => setCheckIn(e.target.value)}
                onFocus={() => (ref.current.type = "date")}
                onBlur={() => (ref.current.type = "text")}
              /> */}
              <DatePicker
                className="search_input ps-1"
                dateFormat="d MMMM yyy"
                minDate={(new Date())}
                placeholderText="Check-In"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                // readOnly
              />
            </div>
          </div>
          <div className="col-md-2 p-0 mb">
          {/* <label></label> */}
            <div className="detailsearchbar border border-dark border-5">
              {/* <input
                className="search_input ps-1"
                type="text"
                placeholder="Check-Out-Date"
                ref={refs}
                name="setCheckoutDate"
                onChange={(e) => setCheckOut(e.target.value)}
                onFocus={() => (refs.current.type = "date")}
                onBlur={() => (refs.current.type = "text")}
              /> */}
              <DatePicker
                className="search_input ps-1"
                dateFormat="d MMMM yyy"
                minDate={(new Date())}
                selected={endDate}
                placeholderText="Check-Out"
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <Dropdown className="col-md-2 p-0" autoClose="outside">
            {/* <label></label> */}
            <Dropdown.Toggle
              id="dropdown-autoclose-outside"
              className="rounded-0 border-end bg_blue"
              variant="dark"
              size="lg"
              title="No Of Guest"
              >
              No Of Guest
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <div className="row">
                  {/* <div className="col-md-6">
                    <h4 className="text-light">No Of Guest</h4>
                  </div> */}
                  <div className="col-md-12">

                    <button
                      className="btn btn-dark me-3"
                      onClick={() => setCounter1(counter1 - 1)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>

                    <span className="text-light fw-bolder">{counter1}</span>
                    <button
                      className="btn btn-dark ms-3"
                      onClick={() => setCounter1(counter1 + 1)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="col-md-2 p-0">
            <button
              className="btn bg_blue btn-lg w-100 text-light"
              onClick={search}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailSearch;
