import {
  faL,
  faLocation,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import SignatureCanvas from "react-signature-canvas";
import { EditorState, convertToRaw } from "draft-js";
import Dropzone from "./Dropzone";
import "../../pages/host/editor.css";
import { Editor } from "react-draft-wysiwyg";
import { Handshake } from "@mui/icons-material";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import { Button } from "react-bootstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../asset/images/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Buttons from "../../component/Buttons";
import ImageUploading from "react-images-uploading";
import house from "../../asset/images/house.jpg";
import apart from "../../asset/images/apart.jpg";
import town from "../../asset/images/town.jpg";
import hotel from "../../asset/images/hotel.jpg";
import bed from "../../asset/images/place.jpg";
import place from "../../asset/images/bedroom.jpg";
import { height } from "@mui/system";
import Maps from "../../component/Maps";
import { set } from "immutable";
import { CirclesWithBar } from "react-loader-spinner";
import Avatar from "react-avatar-edit";
import Modal from "react-bootstrap/Modal";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";

const containerStyle = {
  width: "700px",
  height: "300px",
};

const center = {
  lat: 24.8607343,
  lng: 67.0011364,
};

export const Addlisting = () => {
  const [loader, setLoader] = useState(false);

  const listing = [
    {
      id: 1,
      question: "What kind of property do you have ?",
      option: [
        { name: "House" },
        { name: "Apartment/condo" },
        { name: "Townhouse" },
        { name: "Hotel Room" },
        { name: "Cottage" },
      ],
    },
    {
      id: 2,
      question: "What kind of space will your guests have?",
      option: [{ name: "A Entire Place" }, { name: " A private Room" }],
    },
    // {
    //   id: 3,
    //   question: "What Kind Of Property Do You Have",
    //   option: ["house", " Apartment/condo", "townhouse", "hotel"],
    // },
    // {
    //   id: 4,
    //   question: "What Kind Of Property Do You Have",
    //   option: ["house", " Apartment/condo", "townhouse", "hotel"],
    // },
  ];
  const [questname, setquestName] = useState();
  const [looplist, setLooplist] = useState({
    quest0: "",
    quest1: "",
    quest2: "",
    quest3: "",
  });
  const Checkvalue = (e, i) => {
    setLooplist({ ...looplist, [e.target.name]: e.target.value });
  };
  const isChecked = (index, li, op) => {
    const questname = `quest${index}`;
    return op === looplist[questname];
  };
  const getQuestName = (index) => {
    return `quest${index}`;
  };
  console.log(looplist.quest0, "========property");

  //host name show in side bar
  const [isIndex, setisIndex] = useState(0);
  const token = localStorage.getItem("accesstoken");
  const [hostname, setHostName] = useState("");
  const View = () => {
    var config = {
      method: "get",
      url: `http://flexrentaladmin.sasstechnologies.com/api/host/listning`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setHostName(response.data.listning);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("api", hostname);

  useEffect(() => {
    View();
  }, []);

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();
  const [nameimage, setNameimage] = React.useState();
  const maxNumber = 69;
  const onChange = (imageList) => {
    setImage(imageList[0].file);
    setNameimage(imageList[0].file.name);
    setImages(imageList);
  };
  console.log(nameimage, "...cover photo name");
  console.log(images, "...cover photo");

  // const [images, setImages] = React.useState();
  const [backimage, setBackImage] = React.useState();
  const [backimagename, setBackImageName] = React.useState();
  const [img, setImg] = React.useState();
  // const maxNumber = 69;
  const BackonChange = (imageList) => {
    setBackImage(imageList[0].file);
    setBackImageName(imageList[0].file.name);
    setImg(imageList);
  };
  console.log(backimagename, "...cover back image name");
  console.log(img, "...cover Back photo");

  const [selfieimage, setSelfieImage] = React.useState();
  const [selfieimagename, setSelfieImageName] = React.useState();
  const [selImages, setSelImages] = React.useState();
  const SelfieonChange = (imageList) => {
    setSelfieImage(imageList[0].file);
    setSelfieImageName(imageList[0].file.name);
    setSelImages(imageList);
  };
  console.log(selfieimagename, "...cover selfie image name");
  console.log(selImages, "...cover slefie photo");

  //single image upload work

  //dropzone work
  const [identity, setIdentity] = useState(null);
  console.log("id card", identity);

  const [File, setFile] = useState(null);
  console.log("images", File);

  //cloning work
  const [inputList, setinputList] = useState([
    {
      id: uuid(),
      no_of_bedroom: "",
      no_of_bathroom: "",
    },
  ]);
  const arr = [];
  const handleaddclick = () => {
    arr.push({
      id: uuid(),
      no_of_bedroom: "",
      no_of_bathroom: "",
    });
    setinputList(arr);
    console.log(arr, "req");
  };

  const removeclick = (index) => {
    const list = [...inputList];
    // console.log(index);
    list.splice(index, 1);
    setinputList([...list]);
  };

  //cloning work

  //add listing form states
  const [tittle, setTittle] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  console.log(address);
  const [bathroom, setBathroom] = useState(0);
  const [price, setPrice] = useState(10);
  const [clean, setClean] = useState(10);
  const [deposite, setDeposite] = useState(10);
  const [summary, setSummary] = useState("");
  // const [policy, setPolicy] = useState("");
  const [guest, setGuest] = useState(0);
  const [bedroom, setBedroom] = useState(0);
  const [king, setKing] = useState(0);
  const [queen, setQueen] = useState(0);
  const [double, setDouble] = useState(0);
  const [single, setSingle] = useState(0);
  const [facilities, setFacilities] = useState("");
  const [instruction, setInstruction] = useState("");
  const [scenic, setScenic] = useState("");
  const [hnc, setHnC] = useState([]);
  const [safety, setSafety] = useState("");
  const [bathfeature, setBathfeature] = useState("");
  const [laundaryfeature, setLaundaryfeature] = useState("");
  const [kitchenfeature, setKitchenfeature] = useState("");
  const [entertain, setEntertain] = useState("");
  const [outdoor, setOutdoor] = useState("");
  const [parking, setParking] = useState("");
  const [Position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [PlaceName, setPlaceName] = useState("");

  // const [property_type, setProperty_type] = useState("");

  const [Editors, setEditors] = useState("");
  const [html, setHtml] = useState("");
  console.log(Editors, "editor value checking......");
  const [checkindate, setCheckindate] = useState("");
  const [checkoutdate, setCheckoutdate] = useState("");
  // const [propertyId, setPropertyId] = useState("");

  const handleChangeInput = (floorid, floorevent) => {
    // console.log("a", floorid);
    // console.log("b", floorevent);

    const newInputField = inputList.map((i) => {
      if (floorid === i.id) {
        // console.log("daaaaaaaadaaaaaa123234213", i);
        i[floorevent.target.name] = floorevent.target.value;
        // console.log("id", floorid);
      }
      return i;
    });
    setinputList(newInputField);
    console.log(inputList, ".........bedroom");
  };

  const onEditorStateChange = (editorState) => {
    setEditors(editorState);
    let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtml(data);
    console.log(html, "trext arera");
  };

  // SIde Bar Apis

  const handleChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setFacilities([...facilities, value]);
    } else {
      setFacilities(facilities.filter((e) => e !== value));
    }
    // value = value.join();
    console.log(value, "valuecheckbox");
  };

  const scenicChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setScenic([...scenic, value]);
    } else {
      setScenic(scenic.filter((e) => e !== value));
    }
  };
  const hncChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setHnC([...hnc, value]);
    } else {
      setHnC(hnc.filter((e) => e !== value));
    }
  };
  const safetyChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setSafety([...safety, value]);
    } else {
      setSafety(safety.filter((e) => e !== value));
    }
  };
  const bathChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setBathfeature([...bathfeature, value]);
    } else {
      setBathfeature(bathfeature.filter((e) => e !== value));
    }
  };
  const laundaryChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setLaundaryfeature([...laundaryfeature, value]);
    } else {
      setLaundaryfeature(laundaryfeature.filter((e) => e !== value));
    }
  };
  const kitchenChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setKitchenfeature([...kitchenfeature, value]);
    } else {
      setKitchenfeature(kitchenfeature.filter((e) => e !== value));
    }
  };
  const entertainChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setEntertain([...entertain, value]);
    } else {
      setEntertain(entertain.filter((e) => e !== value));
    }
  };
  const outdoorChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setOutdoor([...outdoor, value]);
    } else {
      setOutdoor(outdoor.filter((e) => e !== value));
    }
  };
  const parkingChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setParking([...parking, value]);
    } else {
      setParking(parking.filter((e) => e !== value));
    }
  };
  // console.log(value, "checking.........");
  console.log(facilities, "checking.........");
  console.log(scenic, "scenic checking.........");
  console.log(hnc, "hnc checking.........");
  console.log(safety, "safety checking.........");
  console.log(bathfeature, "bathfeature checking.........");
  console.log(laundaryfeature, "laundaryfeature checking.........");
  console.log(kitchenfeature, "kitchenfeature checking.........");
  console.log(entertain, "entertain checking.........");
  console.log(outdoor, "outdoor checking.........");
  console.log(parking, "parking checking.........");
  //get checkbox values

  const Navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/hostlogin");
  };

  // Side bar apis end

  // property data post

  const [api, setApi] = useState();
  const add = () => {
    setLoader(true);
    try {
      const listing = new FormData();
      listing.append("title", tittle);
      listing.append("property_type", looplist.quest0);
      listing.append("kind_space", looplist.quest1);
      listing.append("address", PlaceName ? PlaceName : address);
      listing.append("lat", Position.lat);
      listing.append("lng", Position.lng);
      listing.append("cover_photo", imageshow);
      // listing.append("cover_photo", images);
      console.log("cover", imageshow);
      listing.append("no_of_guest_allowed", guest);
      listing.append("no_of_bathrooms", bathroom);
      listing.append("no_of_bedroom", bedroom);
      // listing.append("king_bed", king);
      // listing.append("queen_bed", queen);
      // listing.append("double_bed", double);
      // listing.append("single_bed", single);
      listing.append("rate_per_night", price);
      listing.append("cleaning_fee", clean);
      listing.append("security_deposit", deposite);
      listing.append("property_description", summary);
      listing.append("check_in_instruction", instruction);
      // listing.append("is_bathroom", bedroom);
      inputList.map((e, i) => {
        const bathroom = `no_of_bathroom${i}`;
        listing.append(`is_bathroom[${i}]`, e[bathroom]);
        console.log("===========> avialability bathrrom", e, i);
      });
      inputList.map((e, i) => {
        const bedroom = `no_of_bedroom${i}`;
        listing.append(`bedroom[${i}]`, e[bedroom]);
        console.log("===========> avialability bedroom", e, i);
      });
      File.map((e, i) => {
        listing.append(`photos[${i}]`, e);
        console.log(`photos[${i}]`, e);
      });
      listing.append("cnic_front", image);
      listing.append("cnic_back", backimage);
      listing.append("selfie", selfieimage);
      listing.append("facilities", facilities.join(","));
      listing.append("scenic_view", scenic.join(","));
      listing.append("heating_n_cooling", hnc.join(","));
      listing.append("home_safety", safety.join(","));
      listing.append("bathroom", bathfeature.join(","));
      listing.append("laundary", laundaryfeature.join(","));
      listing.append("kitchen", kitchenfeature.join(","));
      listing.append("entertainment", entertain.join(","));
      listing.append("outdoor", outdoor.join(","));
      listing.append("parking", parking.join(","));
      listing.append("signature", sigpad);

      var config = {
        method: "POST",
        url: `https://flexrentaladmin.sasstechnologies.com/api/add/listning`,
        data: listing,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(function (response) {
          setApi(response.data.property);
          // const { message, sstatus, token } = response.data;
          // console.log(response.data.property, "profile");
          console.log(response, "api");
          console.log(response.data.message, "api");
          console.log(response.data.status, "api");
          // console.log(sstatus, "api");
          if (response.data.status === true) {
            setLoader(false);
            swal({
              title: "Good job! ",
              text: "Your Property added success Fully Wait for the Admin Approval",
              icon: "success",
              button: "Ok",
            });
            Navigate("/hostdashboard");
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (errors) {
      setLoader(false);
      console.log(errors, "error");
      swal({
        title: "Something Went Wrong",
        text: errors.errors ? errors.errors[0] : "check your Details",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const next = () => {
    setisIndex(isIndex + 1);
    // date();
  };
  const back = () => {
    setisIndex(isIndex - 1);
  };
  const ref = useRef();
  const refs = useRef();

  const [src, setSrc] = useState(null);
  const [Preview, setPreview] = useState(null);
  const [coverImage, setCoverImage] = useState([]);
  const [storeImg, setStoreImg] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };
  const SaveImg = () => {
    setStoreImg([...storeImg, { Preview }]);
    setShow(false);
  };

  const imageshow = storeImg.map((item) => item.Preview);

  console.log(storeImg, "=======cover storeImg");
  useEffect(() => {
    console.log(imageshow, "====== crop picture");
  }, [imageshow]);

  const onBedroomChange = (e) => {
    setBedroom(e.target.value);
    // console.log(e.target.value,"abdullah gando hy")
  };
  const Blurchange = (e) => {
    for (let index = 0; index < Number(e.target.value); index++) {
      console.log("running");
      handleaddclick();
    }
  };
  console.log(bedroom);
  const [sigpad, setSigPad] = useState({ trimmedDataURL: null });
  const signCanvas = useRef({});
  const clear = () => {
    signCanvas.current.clear();
    setSigPad("");
  };

  const trim = () => {
    setSigPad(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  console.log(sigpad);
  // let { trimmedDataURL } = sigpad;

  //google map work

  const { isLoaded } = useJsApiLoader({
    // id: "google-map-script",
    googleMapsApiKey: "AIzaSyAt9IOK_D-YxRKKyZgzwyJxxWO503VzHEQ",
    libraries: ["places"],
  });
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    console.log("useeffect" ,+1)
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const Place = await autoCompleteRef.current.getPlace();
      // console.log(place.geometry.location.lat(),"place");
      // console.log(place.geometry.location.lng(),"place");
      setPosition({
        lat: Place.geometry.location.lat(),
        lng: Place.geometry.location.lng(),
      });
      // setPlaceName(place.formatted_address);
      setPlaceName(Place.name);
      console.log(Place, "place");
    });
  }, []);
  console.log(Position.lat, "place");
  console.log(Position.lng, "place");
  console.log(PlaceName, "place");
  console.log(Position, "place");

  //google map work
  return (
    <>
      {/* <Skeleton count={5} /> */}
      <section>
        {loader ? <div className="loader"></div> : null}
        {isIndex < 2 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                {listing.map((li, index) => {
                  return (
                    <>
                      {index === isIndex ? (
                        <>
                          <div
                            className="col-lg-6 h-auto"
                            style={{ backgroundColor: "#000" }}
                          >
                            <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                              <h1 className="text-white text-center">
                                {li.id}&nbsp;
                                {li.question}
                              </h1>
                            </div>
                          </div>
                          <div className="col-lg-6 new1">
                            <div className="">
                              {li?.option.map((op) => (
                                <>
                                  <div className="row px-3 mt-3">
                                    <div
                                      style={{ width: "40%" }}
                                      className={`col-lg-12 text-center mx-auto d-flex justify-content-between align-items-center px-5 py-3 border_radius ${
                                        looplist[getQuestName(index)] ===
                                        op.name
                                          ? "isActive"
                                          : null
                                      }`}
                                    >
                                      <input
                                        id="radio"
                                        type="radio"
                                        name={"quest" + `${index}`}
                                        value={op.name}
                                        onChange={(e) => Checkvalue(e, index)}
                                        // checked="checked"
                                        checked={isChecked(index, op, li)}
                                      />
                                      <h4
                                        className="d-flex align-items-center"
                                        style={{ position: "absolute" }}
                                      >
                                        {op.name}
                                      </h4>

                                      {/* <div className="">
                                        <img
                                          src={op.img}
                                          alt=""
                                          className="rounded"
                                          style={{
                                            width: "70px",
                                            height: "70px",
                                          }}
                                        />
                                      </div> */}
                                    </div>
                                  </div>
                                  <br />
                                </>
                              ))}
                            </div>
                            <div className="row">
                              <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                                {isIndex === 0 ? null : (
                                  <Link
                                    onClick={back}
                                    to="/hostaddproperty"
                                    className="text-dark fw-bolder text-decoration-underline"
                                  >
                                    {" "}
                                    Back
                                  </Link>
                                )}

                                <button
                                  onClick={next}
                                  className="btn btn-theme"
                                  style={{
                                    marginRight: "44%",
                                    padding: "8px 16px",
                                    fontSize: "20px",
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </section>
        ) : null}

        {isIndex === 2 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-auto">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Where is your place located? <br />
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <div
                    style={{
                      // position: "relative",
                      height: "90vh",
                      width: "50vw",
                      top: "10%",
                    }}
                  >
                    <div className="row mt-3 ">
                      <h4 className="text-center">
                        Please enter the address of your place
                      </h4>
                      <div className="col-lg-10 form-group pe-0 mx-auto">
                        <Autocomplete>
                          <input
                            // value={address}
                            // type="text"
                            ref={inputRef}
                            className="form-control border_radius mb-5 "
                            placeholder="Enter Your Address"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </Autocomplete>
                      </div>
                      <div className="" style={{}}>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={Position.lat !== 0 && Position.lng !== 0 ? Position : center}
                          zoom={15}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          
                          <MarkerF position={Position.lat !== 0 && Position.lng !== 0 ? Position : center} />
                          {/* <MarkerF position={{lat:-3.745, lng: -38.523 }} />
                          <MarkerF position={{lat:-3.745, lng: -38.523 }} />
                          <MarkerF position={{lat:-3.745, lng: -38.523 }} />
                          <MarkerF position={{lat:-3.745, lng: -38.523 }} /> */}

                        </GoogleMap>
                      </div>
                    </div>

                    <br />
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {isIndex === 3 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-auto">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh Fixed">
                    <h2 className="text-white">
                      Let your guests know about your property's features!
                    </h2>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <div className="check_listing">
                    {/* 1st */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Listing Details</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-1-outlined"
                            value="Living Room"
                            onChange={handleChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-1-outlined"
                          >
                            Living Room
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-2-outlined"
                          value="Kitchen"
                          onChange={handleChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-2-outlined"
                        >
                          Kitchen
                        </label>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-3-outlined"
                            type="checkbox"
                            value="Dining Room"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-3-outlined"
                          >
                            Dining Room
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-4-outlined"
                            type="checkbox"
                            value="Backyard"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-4-outlined"
                          >
                            Backyard
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-5-outlined"
                            type="checkbox"
                            value="Free Parking"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-5-outlined"
                          >
                            Free Parking
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-6-outlined"
                            value="Lake Access"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-6-outlined"
                          >
                            Lake Access
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-7-outlined"
                            value="Indoor Pool"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-7-outlined"
                          >
                            Indoor Pool
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-8-outlined"
                            value="Outdoor Pool"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-8-outlined"
                          >
                            Outdoor Pool
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-9-outlined"
                            value="Gym"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-9-outlined"
                          >
                            Gym
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-10-outlined"
                            value="Self Check In"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-10-outlined"
                          >
                            Self Check In
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            type="checkbox"
                            id="btn-check-11-outlined"
                            value="Lockbox"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-11-outlined"
                          >
                            Lockbox
                          </label>
                        </div>
                        {/* <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-12-outlined"
                            value="Host Greets"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-12-outlined"
                          >
                            Host Greets
                          </label>
                        </div> */}
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-18-outlined"
                            value="Children's Toys"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-18-outlined"
                          >
                            Children's Toys
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-13-outlined"
                            value="Wifi"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-13-outlined"
                          >
                            Wifi
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-14-outlined"
                            value="Private Entrance"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-14-outlined"
                          >
                            Private Entrance
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-15-outlined"
                            value="Pets Allowed"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-15-outlined"
                          >
                            Pets Allowed
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-16-outlined"
                            value="Building Concierge"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-16-outlined"
                          >
                            Building Concierge
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-17-outlined"
                            value="Smart Lock"
                            onChange={handleChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-17-outlined"
                          >
                            Smart Lock
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 2nd */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Scenic Views</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-19-outlined"
                            value="Canal View"
                            onChange={scenicChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-19-outlined"
                          >
                            Canal View
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-20-outlined"
                          value="City Skyline View"
                          onChange={scenicChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-20-outlined"
                        >
                          City Skyline View
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-21-outlined"
                            value="Lake View"
                            onChange={scenicChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-21-outlined"
                          >
                            Lake View
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-22-outlined"
                            value="Waterfront"
                            onChange={scenicChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-22-outlined"
                          >
                            Waterfront
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-23-outlined"
                            value="Spectacular View"
                            onChange={scenicChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-23-outlined"
                          >
                            Spectacular View
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* 3rd */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Heating and Cooling</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-24-outlined"
                            value="Air Conditioner  "
                            onChange={hncChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-24-outlined"
                          >
                            Air Conditioner
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-25-outlined"
                          value="Heating"
                          onChange={hncChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-25-outlined"
                        >
                          Heating
                        </label>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-26-outlined"
                            type="checkbox"
                            value="Indoor Fireplace"
                            onChange={hncChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-26-outlined"
                          >
                            Indoor Fireplace
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-27-outlined"
                            value="Outdoor Fireplace"
                            onChange={hncChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-27-outlined"
                          >
                            Outdoor Fireplace
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-28-outlined"
                          value="Ceiling Fan"
                          onChange={hncChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-28-outlined"
                        >
                          Ceiling Fan
                        </label>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-29-outlined"
                            type="checkbox"
                            value="Portable Fan"
                            onChange={hncChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-29-outlined"
                          >
                            Portable Fan
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-30-outlined"
                            value="Radiant Heat"
                            onChange={hncChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-30-outlined"
                          >
                            Radiant Heat
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-31-outlined"
                          value="Window AC Unit"
                          onChange={hncChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-31-outlined"
                        >
                          Window AC Unit
                        </label>
                      </div>
                    </div>

                    {/* 4th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Home Safety</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-37-outlined"
                            value="Smoke Alarms"
                            onChange={safetyChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-37-outlined"
                          >
                            Smoke Alarms
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-38-outlined"
                          value="Carbon Monoxide Alarm"
                          onChange={safetyChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-38-outlined"
                        >
                          Carbon Monoxide Alarm
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-39-outlined"
                            value="Fire Extinguisher"
                            onChange={safetyChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-39-outlined"
                          >
                            Fire Extinguisher
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-40-outlined"
                          value="Security Cameras"
                          onChange={safetyChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-40-outlined"
                        >
                          Security Cameras
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-41-outlined"
                            value="First Aid Kit"
                            onChange={safetyChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-41-outlined"
                          >
                            First Aid Kit
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 5th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Bathroom</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-42-outlined"
                            value="Hair Dryer"
                            onChange={bathChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-42-outlined"
                          >
                            Hair Dryer
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-43-outlined"
                          value="Shampoo"
                          onChange={bathChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-43-outlined"
                        >
                          Shampoo
                        </label>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-44-outlined"
                            type="checkbox"
                            value="Conditioner"
                            onChange={bathChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-44-outlined"
                          >
                            Conditioner
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-45-outlined"
                            value="Body Soap"
                            onChange={bathChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-45-outlined"
                          >
                            Body Soap
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-46-outlined"
                          value="Shower Gel"
                          onChange={bathChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-46-outlined"
                        >
                          Shower Gel
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-47-outlined"
                            value="Hair Brush"
                            onChange={bathChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-47-outlined"
                          >
                            Hair Brush
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-48-outlined"
                            type="checkbox"
                            value="Hair Gel"
                            onChange={bathChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-48-outlined"
                          >
                            Hair Gel
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 6th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Laundry</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-49-outlined"
                            value="Washer"
                            onChange={laundaryChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-49-outlined"
                          >
                            Washer
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-50-outlined"
                          value="Dryer"
                          onChange={laundaryChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-50-outlined"
                        >
                          Dryer
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-51-outlined"
                            value="Iron"
                            onChange={laundaryChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-51-outlined"
                          >
                            Iron
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-52-outlined"
                          value="Clothing Storage"
                          onChange={laundaryChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-52-outlined"
                        >
                          Clothing Storage
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-53-outlined"
                            value="Extra Pillows and Blankets"
                            onChange={laundaryChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-53-outlined"
                          >
                            Extra Pillows and Blankets.
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 7th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Kitchen</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-54-outlined"
                            value="Fridge"
                            onChange={kitchenChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-54-outlined"
                          >
                            Fridge
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-55-outlined"
                          value="Stove"
                          onChange={kitchenChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-55-outlined"
                        >
                          Stove
                        </label>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-56-outlined"
                            type="checkbox"
                            value="Dishwasher"
                            onChange={kitchenChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-56-outlined"
                          >
                            Dishwasher
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-57-outlined"
                            type="checkbox"
                            value="Hot Water Kettle"
                            onChange={kitchenChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-57-outlined"
                          >
                            Hot Water Kettle
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-58-outlined"
                            value="Microwave"
                            onChange={kitchenChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-58-outlined"
                          >
                            Microwave
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-59-outlined"
                            value="Cooking Basics"
                            onChange={kitchenChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-59-outlined"
                          >
                            Cooking Basics
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-60-outlined"
                            value="Freezer"
                            onChange={kitchenChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-60-outlined"
                          >
                            Freezer
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-61-outlined"
                          value="Toaster"
                          onChange={kitchenChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-61-outlined"
                        >
                          Toaster
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-62-outlined"
                            value="Coffee Maker"
                            onChange={kitchenChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-62-outlined"
                          >
                            Coffee Maker
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-62-outlined"
                            type="checkbox"
                            value="Wine Glasses"
                            onChange={kitchenChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-62-outlined"
                          >
                            Wine Glasses
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-63-outlined"
                            type="checkbox"
                            value="Baby High Chair"
                            onChange={kitchenChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-63-outlined"
                          >
                            Baby High Chair
                          </label>
                        </div>
                        <div>
                          <input
                            class="btn-check"
                            id="btn-check-64-outlined"
                            type="checkbox"
                            value="Dishes and Silverware"
                            onChange={kitchenChange}
                          />{" "}
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-64-outlined"
                          >
                            Dishes and Silverware
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 8th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Entertainment</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-65-outlined"
                            value="Reading Material"
                            onChange={entertainChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-65-outlined"
                          >
                            Reading Material
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-66-outlined"
                            value="Television in Bedroom"
                            onChange={entertainChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-66-outlined"
                          >
                            Television in Bedroom
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-67-outlined"
                          value="Television"
                          onChange={entertainChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-67-outlined"
                        >
                          Television
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-68-outlined"
                            value="Cable TV"
                            onChange={entertainChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-68-outlined"
                          >
                            Cable TV
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-69-outlined"
                          value="Netflix"
                          onChange={entertainChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-69-outlined"
                        >
                          Netflix
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-70-outlined"
                            value="Crave TV"
                            onChange={entertainChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-70-outlined"
                          >
                            Crave TV
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-71-outlined"
                            value="Disney Plus"
                            onChange={entertainChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-71-outlined"
                          >
                            Disney Plus
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 9th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Outdoor</h4>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-72-outlined"
                            value="Private Balcony"
                            onChange={outdoorChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-72-outlined"
                          >
                            Private Balcony
                          </label>
                        </div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-73-outlined"
                          value="Backyard Patio"
                          onChange={outdoorChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-73-outlined"
                        >
                          Backyard Patio
                        </label>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-74-outlined"
                            value="BBQ Grill"
                            onChange={outdoorChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-74-outlined"
                          >
                            BBQ Grill
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-75-outlined"
                            value="Private Hot Tub"
                            onChange={outdoorChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-75-outlined"
                          >
                            Private Hot Tub
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* 10th */}

                    <div className="row mx-5 px-3 mt-3">
                      <h4 className="my-3">Parking</h4>
                      <div className="col-md-6">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-76-outlined"
                            value="Free Parking on Property"
                            onChange={parkingChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-76-outlined"
                          >
                            Free Parking on Property
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <input
                            type="checkbox"
                            class="btn-check"
                            id="btn-check-77-outlined"
                            value="Free Street Parking"
                            onChange={parkingChange}
                          />
                          <label
                            class="btn btn-outline-secondary mb-2 w-100"
                            for="btn-check-77-outlined"
                          >
                            Free Street Parking
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                      <div>
                        <input
                          type="checkbox"
                          class="btn-check"
                          id="btn-check-1-outlined"
                          value="Private Hot Tub"
                          onChange={handleChange}
                        />
                        <label
                          class="btn btn-outline-secondary mb-2 w-100"
                          for="btn-check-1-outlined"
                        >
                          Private Hot Tub
                        </label>
                      </div>
                    </div> */}
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                        <Link
                          onClick={back}
                          to="/hostaddproperty"
                          className="text-dark fw-bolder text-decoration-underline"
                        >
                          {" "}
                          Back
                        </Link>
                        <button
                          onClick={next}
                          className="btn btn-theme"
                          style={{
                            marginRight: "44%",
                            padding: "8px 16px",
                            fontSize: "20px",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {isIndex === 4 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row h-100vh">
                <div className="col-lg-6 bg-dark ">
                  <div className="d-flex flex-row align-items-center h-100vh my-auto Fixed">
                    <h1 className="text-white text-center">
                      Show your guests what your place looks like!
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1 bg-white">
                  <h4 className="my-3  text-center">Cover Photos</h4>

                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                      src={imageshow.length ? imageshow : house}
                      alt=""
                      onClick={handleShow}
                    />
                  </div>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Cover Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Avatar
                        width={400}
                        height={400}
                        onCrop={onCrop}
                        onClose={onClose}
                        src={src}
                        // onChange={handleCoverImage}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={SaveImg}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* <div className="from-group col-md-12">
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper w-100">
                          <button
                            type="button"
                            className="uplaod"
                            style={
                              isDragging
                                ? { color: "red", width: "100%" }
                                : null
                            }
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or Drop here
                          </button>
                          &nbsp;
                          <div className="">
                            <h4>{nameimage}</h4>
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div> */}

                  <h4 className=" my-3  text-center">Upload upto 10 Photos</h4>
                  <div className="form-group col-md-12">
                    <label className="ms-5">Multiple files</label>
                    <Dropzone setFile={setFile} />
                  </div>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 5 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Let's name your place
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <h2 className="mb-5 text-center">Create a title</h2>
                  <input
                    maxLength={35}
                    className="form-control w-75 mx-auto"
                    id="tittle"
                    placeholder="
Let Them Put A Tittle"
                    value={tittle}
                    rows={5}
                    onChange={(e) => setTittle(e.target.value)}
                  />
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 6 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Let's describe your place
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <h2 className="mb-5 text-center">
                    Give us a brief description of your place in your own words
                  </h2>
                  <textarea
                    className="form-control custom_textarea"
                    id="tittle"
                    placeholder="
Listing Description"
                    rows={5}
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                  ></textarea>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 7 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Let's set a price
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1 pt-5">
                  <h4 className="mb-2 text-center">Daily Rate</h4>
                  <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center align-items-center mb-5">
                      <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setPrice(price - 5)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <input
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50"
                        onChange={(e) => setPrice(e.target.value)}
                        value={price}
                      />
                      <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setPrice((perv) => Number(perv) + 5)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                  <h4 className="mb-2 text-center">Cleaning Fee</h4>
                  <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center align-items-center mb-5">
                      <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setClean(clean - 5)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <input
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50"
                        onChange={(e) => setClean(e.target.value)}
                        value={clean}
                      />
                      <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setClean((perv) => Number(perv) + 5)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                  <div className="row mt-5 pt-5">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 8 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Do you require a security deposite or no? <br />
                      Please make it a reasonable price if so.
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <h4 className="mb-2 text-center">Security Deposit</h4>
                  <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setDeposite(deposite - 5)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                      <input
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50"
                        onChange={(e) => setDeposite(e.target.value)}
                        value={deposite}
                      />
                      <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setDeposite((perv) => Number(perv) + 5)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>

                    <div className="text-center mt-5 pt-5">
                      <input type="checkbox" />
                      &nbsp;&nbsp;
                      <span className="h5">
                        No Security Deposit required from guests
                      </span>
                    </div>
                  </div>
                  <div className="row mt-5 pt-5">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 9 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      What is the Check-In Instructions?
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1">
                  <h2 className="mb-5 text-center">
                    Please describe to your guests how to first check <br />{" "}
                    into your property upon arrival
                  </h2>
                  <textarea
                    className="form-control policy"
                    id="tittle"
                    rows={5}
                    value={instruction}
                    onChange={(e) => setInstruction(e.target.value)}
                  ></textarea>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 10 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      How many guests would you like to welcome?
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1 pt-5">
                  <h4 className="mb-2 text-center">No of Guest</h4>
                  <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center align-items-center mb-5">
                      {/* <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setGuest(guest - 1)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button> */}
                      <input
                        type="number"
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50 form-control"
                        onChange={(e) => setGuest(e.target.value)}
                        value={guest}
                      />
                      {/* <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setGuest((perv) => Number(perv) + 1)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button> */}
                    </div>
                  </div>

                  <h4 className="mb-2 text-center">No of Bedroom</h4>
                  <div className="row">
                    {/* <div className="col-md-3"></div> */}
                    <div className="col-lg-12 d-flex justify-content-center align-items-center mb-5">
                      {/* <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setBedroom(bedroom - 1)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button> */}
                      <input
                        type="number"
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50 form-control"
                        onChange={(e) => onBedroomChange(e)}
                        onBlur={(e) => Blurchange(e)}
                        value={bedroom}
                      />
                      {/* <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setBedroom((perv) => Number(perv) + 1)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button> */}
                    </div>
                  </div>

                  <h4 className="mb-2 text-center">No of Bathroom</h4>
                  <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center align-items-center mb-5">
                      {/* <button
                        className="btn btn-dark me-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setBathroom(bathroom - 1)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button> */}
                      <input
                        type="number"
                        className="text-dark border border-dark py-2 fs-2 rounded text-center w-50"
                        onChange={(e) => setBathroom(e.target.value)}
                        value={bathroom}
                      />
                      {/* <button
                        className="btn btn-dark ms-2 rounded-circle"
                        height="40px"
                        width="70px"
                        onClick={() => setBathroom((perv) => Number(perv) + 1)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button> */}
                    </div>
                  </div>
                  <div className="row mt-5 pt-5">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-2">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {isIndex === 11 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Whay type of bed do you have for each bedroom?
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1 pt-5">
                  <div className="productdiv mb-5">
                    {inputList.map((x, i) => {
                      return (
                        <div className="row mt-4">
                          {/* <div className="form-group col-md-1"></div> */}
                          <div className="form-group col-md-6">
                            <div className="form-check form-check-inline w-100">
                              {/* <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"no_of_bedroom" + `${i}`}
                                    value="Single"
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  /> */}
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Bedroom
                              </label>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => handleChangeInput(x.id, e)}
                                name={"no_of_bedroom" + `${i}`}
                              >
                                <option>select Bed</option>
                                <option value="King Bed">King Bed</option>
                                <option value="Queen Bed">Queen Bed</option>
                                <option value="Double Bed">Double Bed</option>
                                <option value="Single Bed">Single Bed</option>
                              </Form.Select>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="form-check form-check-inline w-100">
                              {/* <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"no_of_bedroom" + `${i}`}
                                    value="Single"
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  /> */}
                              <label class="form-check-label">Bathroom</label>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => handleChangeInput(x.id, e)}
                                name={"no_of_bathroom" + `${i}`}
                              >
                                <option>select Bathroom</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* cloing end */}

                  <div className="row mt-5 pt-5">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme"
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 12 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 bg-dark h-100vh">
                  <div className="d-flex flex-column justify-content-center align-items-center h-100vh">
                    <h1 className="text-white text-center">
                      Please upload the following pictures
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 new1 pt-5">
                  <h4 className=" mb-2  text-center">
                    Please upload the FRONT of your GOVERNMENT ID
                  </h4>
                  <div className="form-group col-md-12">
                    {/* <Dropzone setFile={setIdentity} /> */}

                    <div className="from-group w-75 col-md-12 mx-auto">
                      {/* <label className="ms-5">Multiple files</label> */}
                      <ImageUploading
                        onChange={onChange}
                        value={images}
                        dataURLKey="data_url"
                        acceptType={["jpg", "png", "jpeg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper w-100">
                            <button
                              type="button"
                              className="uplaod"
                              style={
                                isDragging
                                  ? { color: "red", width: "100%" }
                                  : null
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <div className="">
                              <h4>{nameimage}</h4>
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>

                  <h4 className=" mb-2  text-center">
                    Please upload the BACK of your GOVERNMENT ID
                  </h4>
                  <div className="form-group col-md-12">
                    {/* <Dropzone setFile={setIdentity} /> */}

                    <div className="from-group w-75 col-md-12 mx-auto">
                      {/* <label className="ms-5">Multiple files</label> */}
                      <ImageUploading
                        onChange={BackonChange}
                        value={backimage}
                        dataURLKey="data_url"
                        acceptType={["jpg", "png", "jpeg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper w-100">
                            <button
                              type="button"
                              className="uplaod"
                              style={
                                isDragging
                                  ? { color: "red", width: "100%" }
                                  : null
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <div className="">
                              <h4>{backimagename}</h4>
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>

                  <h4 className=" mb-2  text-center">
                    Please upload a selfie of you holding your ID
                  </h4>
                  <div className="form-group col-md-12">
                    {/* <Dropzone setFile={setIdentity} /> */}

                    <div className="from-group w-75 col-md-12 mx-auto">
                      {/* <label className="ms-5">Multiple files</label> */}
                      <ImageUploading
                        onChange={SelfieonChange}
                        value={selfieimage}
                        dataURLKey="data_url"
                        acceptType={["jpg", "png", "jpeg"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper w-100">
                            <button
                              type="button"
                              className="uplaod"
                              style={
                                isDragging
                                  ? { color: "red", width: "100%" }
                                  : null
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </button>
                            &nbsp;
                            <div className="">
                              <h4>{selfieimagename}</h4>
                            </div>
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </div>

                  <div className="row mt-2 pt-5">
                    <div className="col-lg-12 d-flex justify-content-between align-items-center px-5 py-3">
                      <Link
                        onClick={back}
                        to="/hostaddproperty"
                        className="text-dark fw-bolder text-decoration-underline"
                      >
                        {" "}
                        Back
                      </Link>
                      <button
                        onClick={next}
                        className="btn btn-theme "
                        style={{
                          marginRight: "44%",
                          padding: "8px 16px",
                          fontSize: "20px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {isIndex === 13 ? (
          <section className="bg-white">
            <div className="container-fluid">
              <div className="row h-100vh">
                <>
                  <div className="col-lg-6 bg-dark">
                    <div className="d-flex flex-column justify-content-center align-items-center h-100vh my-auto Fixed">
                      <h1 className="text-white text-center">
                        Our Privacy Policy <br /> and <br /> Terms of Conditions
                      </h1>
                    </div>
                  </div>
                  <div className="col-lg-6 new1 pt-5 bg-white">
                    <h6>
                      What is Lorem Ipsum? Lorem Ipsum is simply dummy text of
                      the printing and typesetting industry. Lorem Ipsum has
                      been the industry's standard dummy text ever since the
                      1500s, when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book. It has survived
                      not only five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the 1960s with the release of Letraset
                      sheets containing Lorem Ipsum passages, and more recently
                      with desktop publishing software like Aldus PageMaker
                      including versions of Lorem Ipsum. Why do we use it? It is
                      a long established fact that a reader will be distracted
                      by the readable content of a page when looking at its
                      layout. The point of using Lorem Ipsum is that it has a
                      more-or-less normal distribution of letters, as opposed to
                      using 'Content here, content here', making it look like
                      readable English. Many desktop publishing packages and web
                      page editors now use Lorem Ipsum as their default model
                      text, and a search for 'lorem ipsum' will uncover many web
                      sites still in their infancy. Various versions have
                      evolved over the years, sometimes by accident, sometimes
                      on purpose (injected humour and the like
                    </h6>
                    <span className="">
                      {/* <input type="checkbox" className="me-4" /> */}
                      Agree and Continue with our Privacy Policy and Terms of
                      Condition
                    </span>
                    <div className="mt-3">
                      <h4>Sign</h4>
                      <SignatureCanvas
                        ref={signCanvas}
                        penColor="black"
                        canvasProps={{
                          width: 800,
                          height: 400,
                          className: "sigCanvas",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center alig-item-center">
                      <button className="btn btn-primary" onClick={clear}>
                        Clear
                      </button>
                      <button className="btn btn-primary" onClick={trim}>
                        Save
                      </button>
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-item-center">
                      {sigpad ? <img className="w-50 " src={sigpad} /> : null}
                    </div>
                    ,
                    <div className="row mt-5 ">
                      <div className="col-lg-12 d-flex justify-content-between px-5 py-3">
                        <Link
                          onClick={back}
                          to="/hostaddproperty"
                          className="text-dark fw-bolder text-decoration-underline"
                        >
                          {" "}
                          Back
                        </Link>
                        <button
                          onClick={add}
                          className="btn btn-theme"
                          style={{
                            marginRight: "44%",
                            padding: "8px 16px",
                            fontSize: "20px",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </section>
        ) : null}
      </section>
    </>
  );
};
export default Addlisting;
