import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Footer from "./component/Footer";
// import Footer from "./component/Footer";
// import Header from "./component/Header";
import Home from "./pages/Home";
import Userlogin from "./pages/User-Auth/Userlogin";
import Usersignup from "./pages/User-Auth/Usersignup";
import Hostlogin from "./pages/Host-Auth/Hostlogin";
import Hostsignup from "./pages/Host-Auth/Hostsignup";
import Verifyemail from "./pages/Host-Auth/Verifyemail";
import Hostdashboard from "./pages/host/Hostdashboard";
import Searchapp from "./component/Search";
import Listing from "./pages/host/Listing";
import Edit_listing from "./pages/host/Edit_listing";
import Addlisting from "./pages/host/Addlisting";
import AllProperty from "./pages/User/AllProperty";
import Userforgot from "./pages/User-Auth/Userforgot";
import Resetpass from "./pages/User-Auth/Resetpass";
import Hostforgot from "./pages/Host-Auth/Hostforgot";
import Reset from "./pages/Host-Auth/Reset";
import Listingdetail from "./pages/User/Listingdetail";
import HostProfile from "./pages/host/HostProfile";
import UserProfile from "./pages/User/UserProfile";
import { useState } from "react";
import { useEffect } from "react";
import Welcome from "./pages/host/Welcome";
import { Pagenotfound } from "./component/Pagenotfound";
import DetailSearch from "./component/DetailSearch";
import {SearchProvider} from "./context/Searchcontext";
function App() {
  return (
    <div className="">

      <SearchProvider >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/userlogin" element={<Userlogin />} />
          <Route exact path="/usersignup" element={<Usersignup />} />
          <Route exact path="/userforgot" element={<Userforgot />} />
          <Route exact path="/reset" element={<Resetpass />} />
          <Route exact path="/Hostlogin" element={<Hostlogin />} />
          <Route exact path="/Hostsignup" element={<Hostsignup />} />
          <Route exact path="/Verifyemail" element={<Verifyemail />} />
          <Route exact path="/hostforgot" element={<Hostforgot />} />
          <Route exact path="/hostreset" element={<Reset />} />
          <Route
            exact
            path="/Hostdashboard"
            element={<Hostdashboard />}
          />
          {/* <Route exact path="/hostproperty" element={<Property />} /> */}
          <Route exact path="/editlisting/:id" element={<Edit_listing />} />
          <Route exact path="/hostaddproperty" element={<Addlisting />} />
          <Route exact path="/AllProperty" element={<AllProperty />} />
          <Route exact path="/listingdetail:id" element={<Listingdetail />} />
          <Route exact path="/hostprofile" element={<HostProfile />} />
          <Route exact path="/welcome" element={<Welcome />} />
          <Route exact path="/userprofile" element={<UserProfile />} />
          <Route exact path="/*" element={<Pagenotfound/>} />
          <Route exact path="/detailsearch" element={<DetailSearch />} />
        </Routes>
      </BrowserRouter>
      {/* <DetailSearch/> */}
      </SearchProvider>
    </div>
  );
}

export default App;
