import React from 'react'
import { Link } from 'react-router-dom';
import property from '../../asset/images/property.jpg'
import '../../pages/Custom.css'

export const Welcome = () => {
  return (
    <>
       <section className="bg-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-0">
                <img alt='property-img' className='property_img img-fluid' src={property}/>
          </div>
          <div className="col-lg-6 bg-dark h-100vh">
            <div className="d-flex flex-column justify-content-center align-items-center h-89vh mt-5">
              <h1 className="text-white text-center" style={{fontSize: "44px"}}>Welcome To Flex Rentals</h1>
              <h2 className='d-block text-white mt-3'>Let's give Hosting a try </h2>
             
              <div className="row">
                  <div className="col-lg-12 d-flex justify-content-between px-5 py-3">
                    {/* <Link
                      to="/Hostdashboard"
                      className="text-light fw-bolder text-decoration-underline"
                    >
                      {" "}
                      Skip
                    </Link> */}
                    <Link to="/hostaddproperty" className="btn btn-theme mx-auto mt-5" style={{padding: "8px 16px",
                            fontSize: "20px"}}>
                      Let's Go
                    </Link>
                  </div>
            </div>

            </div>
            
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
export default Welcome;