import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";
import logo from "../../asset/images/new_logo.png";

export const Userforgot = () => {
  //Yup Validation start

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  //Yup Validation start

  //forgot API Fetching start

  const Navigate = useNavigate();

  const forgot = async (V) => {
    try {
      const response = await axios.post("/forgot", V);
      const { message, status } = response.data;
      if (status == true) {
        // setSession(token);
        swal({
          title: "Good job!",
          text: "Check Your Email!",
          icon: "success",
          button: "Ok",
        });
        Navigate("/reset");
      } else {
        Navigate("/userforgot");
      }
    } catch (error) {
      console.log(error?.message);
      swal({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };

  //forgot API Fetching end

  //protected routing start

  //   const token = localStorage.getItem("accesstoken");
  //   useEffect(()=>{
  //     console.log(token)
  // if(token){
  //  Navigate("/");
  // }else{
  //   Navigate("/userlogin")
  // }
  //   },[])

  //protected routing end

  return (
    <>
      <section className="bg-login h-100vh" style={{ backgroundColor: "#000" }}>
        <div>
          <img
            className="text-center mx-auto d-block"
            width={"10%"}
            src={logo}
            alt=""
          />
        </div>
        <Authheader />
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // same shape as initial values
            forgot(values);
          }}
        >
          {({ errors, touched }) => (
            <div className="container-fluid p-5 mt-5">
              <Form>
                <div className="row">
                  <div className="offset-lg-4 col-lg-4">
                    <div className="card shadow border-radious bg-card">
                      <div className="card-header">
                        <h4 className="text-light fw-bolder text-center">
                          Welcome To Forgot
                        </h4>
                      </div>
                      <div className="card-body">
                        <label className="form-label fw-bolder fs-6 text-white">
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          className="col-lg-12 form-control mb-3"
                          placeholder="Enter Your Email"
                        />
                        {errors.email && touched.email ? (
                          <div className="text-danger">{errors.email}</div>
                        ) : null}

                        <button
                          type="submit"
                          className="btn btn-light offset-lg-3 col-lg-6 mt-2"
                        >
                          Forgot
                        </button>

                        {/* <div className="col-lg-12 text-center mt-2">
                        <p className="text-light">
                          If You Don't Have Account ?
                          <Link to="/usersignup" className="text-white">
                            Sign Up
                          </Link>
                        </p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </section>
    </>
  );
};

export default Userforgot;
