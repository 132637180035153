import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import logo from "../../asset/images/new_logo.png"

export const Hostsignup = () => {
  const [loader, setLoader] = useState(false);

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   'Must match "password" field value'
    // ),
    phone: Yup.string()
      .required("Phone Number Is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(12, "Please enter 12 digits"),
  });
  const Navigate = useNavigate();

   const signupfunc = async (V) => {
    setLoader(true);
    try {
     const response = await axios.post("/signup", V);
    console.log(response, "workig");
     const { message, status, token } = response.data;
       console.log(message, "message");
       if (status === true) {
         setSession(token);
         swal({
           title: "Good job!",
           text: message,
           icon: "success",
           button: "Ok",
         });
         Navigate("/Hostlogin");
       } else {
        setLoader(false)
       }
    } catch (errors) {
       setLoader(false);
       console.log(errors.errors[0]);
       swal({
         title: "Something Went Wrong",
         text: errors.errors[0],
         icon: "error",
         dangerMode: true,
       });
     }
   };
  return (
    <>
    <section className="bg-login h-100vh h-auto" style={{backgroundColor: "#000"}}>
      {/* <Authheader /> */}
      <div>

      <img className="text-center mx-auto d-block" width={"15%"} src={logo} alt="" />
      </div>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          password: "",
          // password_confirmation: "",
          role: "host",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          console.log(values, "values");
          // same shape as initial values
          signupfunc(values);
        }}
      >
       {({ errors, touched }) => (
         <div className="container-fluid pt-4 pb-5 mb-signup">
              {loader ? (<div className="loader"></div>) : (null)}     
          <Form>
              <div className="row">
                <div className="offset-lg-2 col-lg-8">
                  <div className="card bg-card">
                    <div className="card-header">
                      <h2 className="text-light fw-bolder text-center">
                        Ready to become a Host?
                      </h2>
                      <h4 className="text-light text-center mt-3">
                        Fill out the information below and <br /> Sign Up!
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="row mx-auto">
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <h4 className="form-label text-white fw-bolder">First Name</h4>
                          <Field
                            name="first_name"
                            type="text"
                            className=" form-control mb-1 py-4"
                            placeholder="Enter Your First Name"
                          />
                          {errors.first_name && touched.first_name ? (
                            <p className="text-danger">{errors.first_name}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <h4 className="form-label text-white fw-bolder">Last Name</h4>
                          <Field
                            name="last_name"
                            type="text"
                            className=" form-control mb-1 py-4"
                            placeholder="Enter Your Last Name"
                          />
                          {errors.last_name && touched.last_name ? (
                            <p className="text-danger">{errors.last_name}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <h4 className="form-label text-white fw-bolder">Email</h4>
                          <Field
                            name="email"
                            type="email"
                            className=" form-control mb-1 py-4"
                            placeholder="Enter Your Email"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <h4 className="form-label text-white fw-bolder">
                            Phone Number
                          </h4>
                          <Field
                            name="phone"
                            type="number"
                            className="col-lg-4 form-control mb-1 py-4"
                            placeholder="Enter Your phone number"
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <h4 className="form-label text-white fw-bolder">
                            Password
                          </h4>
                          <Field
                            name="password"
                            type="password"
                            className="col-lg-12 form-control mb-1 py-4"
                            placeholder="Enter Your Password"
                          />
                          {errors.password && (
                            <p className="text-danger">{errors.password}</p>
                          )}
                        </div>
                        </div>
                       
                        <div className="row mt-2">
                          <div className="col-lg-12 d-flex justify-content-center align-items-center">
                        <button
                          type="submit"
                          className="btn btn-theme mt-2 fs-5"
                          // onClick={()=>alert("ok")}
                        >
                          SIGN UP
                        </button>
                        </div>
                        </div>
                        <div className="col-lg-12 text-center mt-4">
                          <h4 className="text-light">
                            Already Have a Flex-Rental Account ?<br/>{" "}
                            <Link to="/hostlogin" className="text-white">
                              Login
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Form>
            </div>
        )}
      </Formik>

    </section>
      {/* <Authfooter /> */}
      </>
  );
};
export default Hostsignup;
