import { useState } from "react";
import "../../pages/Custom.css";
import Authheader from "../../component/Authheader";
import Form from "react-bootstrap/Form";
import logo from "../../asset/images/logo.png";
import profileimg from "../../asset/images/profile-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { Token } from "@mui/icons-material";
import axios from "../../utlilites/axios";
import swal from "sweetalert";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const HostProfile = () => {
  const [loader, setLoader] = useState(false);
  const [images, setImage] = useState("");
  const [img, setImg] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profileapi, setProfileApi] = useState("");

  const handleChange = (e) => {
    console.log(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
  };

  const token = localStorage.getItem("accesstoken");

  const Navigate = useNavigate();
  const update = () => {
    setLoader(true);
    try {
      const profile = new FormData();
      profile.append("image", img);
      profile.append("first_name", first);
      profile.append("last_name", last);
      profile.append("email", email);
      profile.append("phone", phone);

      var config = {
        method: "POST",
        url: `https://flexrentaladmin.sasstechnologies.com/api/profile/update`,
        data: profile,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          setProfileApi(response.data.listning);
          if (response.data.status === true) {
            setLoader(false);
            swal({
              title: "Good job! ",
              text: "Your Profile Update Successfully",
              icon: "success",
              button: "Ok",
            });
            Navigate("/hostdashboard");
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (errors) {
      setLoader(false);
      console.log(errors, "error");
      swal({
        title: "Something Went Wrong",
        text: errors.errors ? errors.errors[0] : "check your Details",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  // useEffect(()=>{
  //   setLoader(false)
  //   update();
  // }, []);
  console.log(images, phone, first, last, email, "checking");

  return (
    <>
      <Authheader />
      {loader ? <div className="loader"></div> : null}
      <section className="host_profile_section">
        <div className="card container_custom box_shadow border_radius pt-2 pb-2 px-2 margin_100">
          <h2 className="text-center mb-3">Host Profile</h2>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center text-center flex-direction-column">
                  <div className="avatar-wrapper">
                    <img
                      className="img-fluid"
                      src={images ? images : profileimg}
                      alt=""
                    />
                    <div className="camera_main_div">
                      <input
                        type="file"
                        className="update-img "
                        onChange={handleChange}
                      />
                      <i>
                        <FontAwesomeIcon icon={faCamera} />
                      </i>
                    </div>
                    <br />
                    <br />
                  </div>
                  <h4>
                    {first} {last}
                  </h4>
                </div>
                <div className="col-md-6 px-5 ">
                  <div>
                    <label>First Name</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="text"
                      placeholder="First Name"
                      value={first}
                      onChange={(e) => setFirst(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label>Last Name</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="text"
                      placeholder="Last Name"
                      value={last}
                      onChange={(e) => setLast(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label>Email</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label>Phone Number</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="number"
                      placeholder="Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      className="btn btn-dark w-100 py-2"
                      onClick={update}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default HostProfile;
