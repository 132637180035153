import React from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

{
  /* <style>
.dropzone {
  padding: 10px;
  border: 2px dashed;
  text-align: center;
}
</style> */
}

function Dropzone({ setFile }) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": [],
      },
    });
  setFile(acceptedFiles);

  const acceptedFileItems = acceptedFiles.map((file) => (
    <div className="col-md-4">
      <img src={URL.createObjectURL(file)} className="w-100 h-16"/>
      <p key={file.path}>
        {file.path} - {file.size} bytes
      </p>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only *.jpeg *.jpg and *.png images will be accepted)</em>
      </div>
      <aside>
        <h4>Accepted files</h4>
        <div className="row">{acceptedFileItems}</div>

        <h4>Rejected files</h4>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </section>
  );
}
export default Dropzone;
// <Dropzone />
