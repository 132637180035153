import { useEffect } from "react";
import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";
import axios from "../../utlilites/axios";
import logo from "../../asset/images/new_logo.png"

export const Usersignup = () => {

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   'Must match "password" field value'
    // ),
    phone: Yup.string()
      .required("Phone Number Is Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(12, "Please enter 12 digits"),
  });
  const Navigate = useNavigate();

   const signupfunc = async (V) => {
    try {
      const response = await axios.post("/signup", V);
      console.log(response, "workig");
      const { message, status, token } = response.data;
      if (status === true) {
        setSession(token);
        swal({
          title: "Good job!",
          text: message,
          icon: "success",
          button: "Ok",
        });
        Navigate("/");
      } else {
      }
    } catch (errors) {
      console.log(errors.errors[0]);
      swal({
        title: "Something Went Wrong",
        text: errors.errors[0],
        icon: "error",
        dangerMode: true,
      });
    }
  };
  
  
  
  // const token = localStorage.getItem("accesstoken");
  // useEffect(() => {
  //   console.log(token);
  //   if (token) {
  //     Navigate("/");
  //   } else {
  //     Navigate("/userlogin");
  //   }
  // }, []);
 
 
  return (
    <section className="bg-login h-100vh" style={{backgroundColor: "#000"}}>
       <img className="text-center mx-auto d-block" width={"10%"} src={logo} alt="" />
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          password: "",
          // password_confirmation: "",
          role: "customer",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          console.log(values, "values");
          // same shape as initial values
          signupfunc(values);
        }}
      >
        {({ errors, touched }) => (
            <div className="container-fluid pt-1 mb-signup">
          <Form>
              <div className="row">
                <div className="offset-lg-2 col-lg-8">
                  <div className="card shadow border-radious bg-card">
                    <div className="card-header">
                      <h4 className="text-light fw-bolder text-center">
                        Welcome To Sign Up
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="row mx-auto">
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <span className="form-label text-white">First Name</span>
                          <Field
                            name="first_name"
                            type="text"
                            className=" form-control mb-1"
                            placeholder="Enter Your First Name"
                          />
                          {errors.first_name && touched.first_name ? (
                            <p className="text-danger">{errors.first_name}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <span className="form-label text-white">Last Name</span>
                          <Field
                            name="last_name"
                            type="text"
                            className=" form-control mb-1"
                            placeholder="Enter Your Last Name"
                          />
                          {errors.last_name && touched.last_name ? (
                            <p className="text-danger">{errors.last_name}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <span className="form-label text-white">Email</span>
                          <Field
                            name="email"
                            type="email"
                            className=" form-control mb-1"
                            placeholder="Enter Your Email"
                          />
                          {errors.email && touched.email ? (
                            <p className="text-danger">{errors.email}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <span className="form-label text-white">
                            Phone Number
                          </span>
                          <Field
                            name="phone"
                            type="number"
                            className="col-lg-4 form-control mb-1"
                            placeholder="Enter Your phone number"
                          />
                          {errors.phone && touched.phone ? (
                            <p className="text-danger">{errors.phone}</p>
                          ) : null}
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-12 w-50 mx-auto">
                          <span className="form-label text-white">
                            Password
                          </span>
                          <Field
                            name="password"
                            type="password"
                            className="col-lg-12 form-control mb-1"
                            placeholder="Enter Your Password"
                          />
                          {errors.password && (
                            <p className="text-danger">{errors.password}</p>
                          )}
                        </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-12 w-50 mx-auto">
                          <button
                          type="submit"
                          className="btn btn-light offset-lg-3 col-lg-6 mt-2"
                          // onClick={()=>alert("ok")}
                        >
                          SignUp
                        </button>
                          </div>
                        </div>
                        <div className="col-lg-12 text-center">
                          <p className="text-light">
                            If You Have Account ?{" "}
                            <Link to="/userlogin" className="text-white">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Form>
            </div>
        )}
      </Formik>
    </section>
  );
};

export default Usersignup;
