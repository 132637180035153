import { React, useRef, useState, useEffect } from "react";
import "../../pages/Custom.css";
import Detailheader from "../../component/Detailheader";
import DetailSearch from "../../component/DetailSearch";

import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faBed,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Maps from "../../component/Maps";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { accordionDetailsClasses } from "@mui/material";
import axios from "../../utlilites/axios";
import { useParams } from "react-router-dom";
//stripe payment
import StripeCheckout from "react-stripe-checkout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "600px",
};

const center = {
  lat: 24.8607343,
  lng: 67.0011364,
};

export const Listingdetail = () => {
  // react datepicker
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const ref = useRef();
  const refs = useRef();
  const [loader, setLoader] = useState(false);
  const [counter1, setCounter1] = useState(0);
  const [facilities, setFacilities] = useState([]);
  const [entertainment, setEntertainment] = useState([]);
  const [heating_n_cooling, setHeating_n_cooling] = useState([]);
  const [home_safety, setHome_safety] = useState([]);
  const [kitchen, setKitchen] = useState([]);
  const [laundary, setLaundary] = useState([]);
  const [outdoor, setOutdoor] = useState([]);
  const [parking, setParking] = useState([]);
  const [scenic_view, setScenic_view] = useState([]);
  const [clean, setClean] = useState("");
  const [deposit, setDeposit] = useState("");
  const [tax, setTax] = useState(10);
  const [details, setDetails] = useState([]);
  const [Position, setPosition] = useState({lat: 0 , lng: 0});

  //fetch API of details

  const { id } = useParams();
  const token = localStorage.getItem("accesstoken");
  function detail() {
    setLoader(true);
    var config = {
      method: "get",
      url: `https://flexrentaladmin.sasstechnologies.com/api/listning/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        console.log(response?.data?.listning, "propertydetails");
        setDetails(response?.data?.listning);
        setFacilities(response?.data?.listning?.facilities);
        setEntertainment(response?.data?.listning?.entertainment);
        setHeating_n_cooling(response?.data?.listning?.heating_n_cooling);
        setHome_safety(response?.data?.listning?.home_safety);
        setKitchen(response?.data?.listning?.kitchen);
        setLaundary(response?.data?.listning?.laundary);
        setOutdoor(response?.data?.listning?.outdoor);
        setParking(response?.data?.listning?.parking);
        setScenic_view(response?.data?.listning?.scenic_view);
        setPosition({lat:parseFloat(response?.data?.listning?.lat), lng:parseFloat(response?.data?.listning?.lng),});
        if (response.status) {
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  console.log(details, "desc");

  useEffect(() => {
    detail();
  }, []);
  console.log(Position,"position")

  //stripe payment

  const onToken = (token) => {
    fetch("/save-stripe-token", {
      method: "POST",
      body: JSON.stringify(token),
    }).then((response) => {
      response.json().then((data) => {
        alert(`We are in business, ${data.email}`);
      });
    });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Detailheader />
      <div className="py-3">
        <DetailSearch classna="text-light" />
      </div>

      <section className="bg-dark">
        <div className="container listing_detail_container bg-dark text-light py-5 mt-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row mt-2">
                <h4 className="text-light">
                  {details?.title},
                  <span className="mx-4 text-light">
                    {details?.property_type},
                  </span>
                  <span className="text-light">{details?.address}</span>
                </h4>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="slider mt-1 ">
                    <Swiper
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {details?.photos?.map((x) => (
                        <SwiperSlide>
                          <img
                            src={`http://flexrental.dev-um.xyz/storage/${x?.image}`}
                            alt=""
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    {details.photos?.map((y) => (
                      <div className="col-md-6 mt-3 mb-2 mt-md-0">
                        <img
                          className="img-fluid banner1img"
                          src={`http://flexrental.dev-um.xyz/storage/${y.image}`}
                          alt="banner img"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <h5 className="text-light">
                  {details?.no_of_guest_allowed} Guest{" "}
                  <span className="mx-4 text-light">
                    {details?.no_of_bedrooms} Bedroom
                  </span>
                  <span className="mx-4 text-light">
                    {details?.no_of_bathrooms} Bathrooms
                  </span>
                  <span className="text-light">{details?.kind_space}</span>
                </h5>
              </div>

              <hr className="my-1 bg-light" />

              <div class="row mt-3">
                <div class="col">
                  <label className="mb-2 h5" htmlFor="summary">
                    Listing Details:
                  </label>
                  <p class="p-3 desc">{details?.property_description}</p>
                </div>
              </div>

              <hr className="my-4 bg-light" />

              <div className="row mt-3">
                <label className="mb-2 h5" htmlFor="bedrooms">
                  Bedrooms:
                </label>
                {details?.listning_beds?.map((z) => (
                  <div className="col-md-3">
                    <div className="mt-3 mt-md-0 p-2 border border-light border-2 rounded ">
                      <div>
                        <FontAwesomeIcon icon={faBed} />
                        <h4 className="text-white mt-2">
                          {z.bed_size ? z.bed_size : "No Bed"}
                        </h4>
                      </div>
                      <p className="fs-6">
                        {" "}
                        with bathroom <br /> {z.bath ? z.bath : "No bath"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <hr className="my-4 bg-light" />

              <div className="row">
                <label className="mb-2 h5" htmlFor="facilities">
                  Listing Features
                </label>
                <div className="col-12 mt-3">
                  <div className="row">
                    <div className="col-4">
                      {facilities?.map((f) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />
                          &nbsp;&nbsp;{f}
                        </div>
                      ))}
                      <br />
                    </div>
                    <br />
                    <div className="col-4">
                      {entertainment?.map((en) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {en}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {heating_n_cooling?.map((h) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {h}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {home_safety?.map((ho) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {ho}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {kitchen?.map((k) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {k}
                        </div>
                      ))}
                      <br />
                    </div>
                    <br />
                    <div className="col-4">
                      {laundary?.map((l) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {l}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {outdoor?.map((o) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {o}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {parking?.map((p) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {p}
                        </div>
                      ))}
                    </div>
                    <br />
                    <div className="col-4">
                      {scenic_view?.map((s) => (
                        <div>
                          <input
                            className="font_10"
                            type="checkbox"
                            // value="Living Room"
                            checked
                          />{" "}
                          &nbsp;&nbsp; {s}
                        </div>
                      ))}
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              <hr className="my-4 bg-light" />

              {/* <div className="row">
                <label className="mb-2 h5" htmlFor="facilities">
                  Where you will be
                </label>
               
              </div> */}

              <hr className="my-4 bg-light" />

              <div className="row">
                <h2 className="my-4  text-white">Host Information</h2>
                <div className="col-md-1 pe-0">
                  <img
                    src={`http://flexrental.dev-um.xyz/storage/${details?.user?.image}`}
                    className="rounded-circle w-100"
                  />
                </div>
                <div className="col-md-6">
                  <h4 className="p-2 text-white">
                    {details?.user?.first_name} {details?.user?.last_name}
                  </h4>
                </div>

                <div className="col-md-12 mt-5">
                  <h4 className="text-white">Check In Instruction</h4>
                  <p className="text-white desc">
                    {details?.check_in_instruction}
                  </p>
                </div>
              </div>

              <hr className="my-4 bg-light" />

              <div className="row">
                <label className="my-3 d-flex align-items-baseline">
                  <FontAwesomeIcon className="ps-2" icon={faStar} />
                  <span className="ps-2 h5">4.98 · 125 reviews</span>
                </label>
                <div className="col-md-6">
                  <ul class="list-group box_shadow">
                    <div className="d-flex justify-content-between border">
                      <li className="list-group-item bg-transparent newli text-light">
                        Cleanliness
                      </li>
                      <li className="list-group-item bg-transparent newli">
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                      </li>
                    </div>
                    <div className="d-flex justify-content-between border">
                      <li className="list-group-item bg-transparent newli text-light">
                        Host
                      </li>
                      <li className="list-group-item bg-transparent newli">
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                      </li>
                    </div>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul class="list-group mt-3 mt-md-0 box_shadow">
                    <div className="d-flex justify-content-between border">
                      <li className="list-group-item bg-transparent newli text-light">
                        Check-in
                      </li>
                      <li className="list-group-item bg-transparent newli">
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                      </li>
                    </div>
                    <div className="d-flex justify-content-between border">
                      <li className="list-group-item bg-transparent newli text-light">
                        Value
                      </li>
                      <li className="list-group-item bg-transparent newli">
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                        <FontAwesomeIcon
                          className="ps-2 text-light"
                          icon={faStar}
                        />
                      </li>
                    </div>
                  </ul>
                </div>
              </div>

              <hr className="my-4 bg-light" />

              <div className="row">
                <label className="my-3 d-flex align-items-baseline">
                  <span className=" h5">Cancellation policy</span>
                </label>
                <div className="col-md-12">
                  This reservation is non-refundable.
                  {/* <br /> Review the Host’s
                  full cancellation policy which applies even if you cancel for{" "}
                  <br />
                  illness or disruptions caused by COVID-19. */}
                </div>
              </div>
            </div>

            {/* Form start here */}

            <div className="col-md-4 pt-4">
              <Form>
                <div className="card box_shadow w-100 form_card_main">
                  <div className="container">
                    <div className="row py-3">
                      <div className="col-md-6">
                        <div className="detailsearchbar border border-dark border-5">
                          <DatePicker
                            className="search_input ps-1"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="detailsearchbar border border-dark border-5">
                          <DatePicker
                            className="search_input ps-1"
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <Dropdown
                          className="col-md-12 my-3 p-0 mb"
                          autoClose="outside"
                        >
                          <Dropdown.Toggle
                            id="dropdown-autoclose-outside"
                            className="rounded-0 border-end"
                            variant="dark"
                            size="lg"
                            title="No Of Guest"
                          >
                            No Of Guest
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item className="bg-dark">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="text-light">No Of Guest</h4>
                                </div>
                                <div className="col-md-6">
                                  <button
                                    className="btn btn-dark me-2"
                                    onClick={() => setCounter1(counter1 + 1)}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </button>
                                  <span className="text-light">{counter1}</span>
                                  <button
                                    className="btn btn-dark ms-2"
                                    onClick={() => setCounter1(counter1 - 1)}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </button>
                                </div>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="row">
                          <div className="col-md-12">
                            <StripeCheckout
                              token={() => onToken()}
                              stripeKey="my_PUBLISHABLE_stripekey"
                            >
                              <button
                                type="submit"
                                className="btn btn-dark w-100 py-2 mb-3"
                              >
                                <h5 className="text-light"> Request To Book</h5>
                              </button>
                            </StripeCheckout>
                          </div>
                        </div>
                      </div>
                      <div className="row pe-0">
                        <div className="col-md-12 pe-0">
                          <ul class="list-group">
                            <div className="d-flex justify-content-between border">
                              <li className="list-group-item newli">
                                Rate per night:
                              </li>
                              <li className="list-group-item newli">
                                {details.rate_per_night} $
                              </li>
                            </div>
                            <div className="d-flex justify-content-between border">
                              <li className="list-group-item newli">
                                Cleaning Fee:
                              </li>
                              <li className="list-group-item newli">
                                {details.cleaning_fee} $
                              </li>
                            </div>
                            <div className="d-flex justify-content-between border">
                              <li className="list-group-item newli">
                                Deposite Fee:
                              </li>
                              <li className="list-group-item newli">
                                {details.security_deposit} $
                              </li>
                            </div>
                            <div className="d-flex justify-content-between border">
                              <li className="list-group-item newli">Taxes:</li>
                              <li className="list-group-item newli">
                                103,65 $
                              </li>
                            </div>

                            <hr />

                            <div className="d-flex justify-content-between border">
                              <li className="list-group-item newli">Total:</li>
                              <li className="list-group-item newli">
                                {details?.rate_per_night +
                                  details?.cleaning_fee +
                                  details?.security_deposit +
                                  10}{" "}
                                $
                              </li>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>

              <div className="row mt-5">
                <div className="col-md-12">
                <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={details.lat !== "undefined" && details.lng !== "undefined" ? Position : center}
                          zoom={12}
                          options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                          }}
                        >
                          
                          <MarkerF position={details.lat !== "" && details.lng !== "undefined" ? Position : center} />

                        </GoogleMap>
                  </div>
              </div>
            </div>

            {/* Form end here */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Listingdetail;
