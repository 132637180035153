import React from "react";
import ReactDOM, { Link } from "react-router-dom";
import "../pages/Custom.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function Searchapp() {
  const [APIData, setAPIData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    axios
      .get(`http://flexrentaladmin.sasstechnologies.com/api/listning`)
      .then((response) => {
        setAPIData(response.data.property);
        console.log(response.data.property[0].address);
        console.log(response.data.property);
      });
  }, []);

  const searchItems = (searchValue) => {
    // setSearchInput(searchValue);
    if (searchValue !== "") {
      console.log(searchValue);
      const filteredData = APIData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      console.log(filteredData, "filter");
      setFilteredResults(filteredData);
    } else {
      setFilteredResults('');
    }
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6 offset-md-3">
            <div className="searchbar">
              <input
                className="search_input"
                type="text"
                name=""
                placeholder="Search..."
                onChange={(e) => searchItems(e.target.value)}
              />
              <a href="#" className="search_icon">
                <FontAwesomeIcon icon={faSearch} />
              </a>
            </div>
          </div>
          <div className="row front">
            <div className="offset-md-3 col-md-6 ">
              <div className="scroll">
                {filteredResults.length !== 0
                  ? filteredResults.map((item) => {
                      return (
                        <Link to={`/propertydetail${item.id}`} className="">
                          <div className="card res cardi">
                            <div className="result">
                              <div className="result_input">
                                <div className="row">
                                  <div className="col-md-6">
                                    <h5 className="card-name mt-2">
                                      {item.property_type}
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h6 className="text-end mt-2">
                                      {item?.city}, {item?.country}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  : // <div className="card res cardi ">
                    //   <div className="result">
                    //     <div className="result_input">
                    //       <div className="row">
                    //         <div className="col-md-6">
                    //           <h5 className="card-name mt-2">no item found</h5>
                    //         </div>

                    //         <div className="col-md-6">
                    //           <h6 className="text-end mt-2">no item found</h6>
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                    null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Searchapp;
