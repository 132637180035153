import React from "react";
import logo from "../../asset/images/new_logo.png";

export const Verifyemail = () => {
  return (
    <>
      <section style={{ backgroundColor: "#000", height: "100vh" }}>
        <div>
          <img
            className="text-center mx-auto d-block"
            width={"10%"}
            src={logo}
            alt=""
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className="text-light text-center mt-5">
                Please check your email to <br />
                Activate your profile and <br />
                please login below
              </h4>


              <div className="mx-auto verify_div"> 
                <label className="text-light mb-2">Verify Email</label>
                <input
                  className="form-control"
                  placeholder="Verify Email"
                  type="email"
                />
              </div>
              <div className="mt-4">
                <button className="btn btn-theme fs-5 mx-auto d-block px-4">Login</button>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Verifyemail;
