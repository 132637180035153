import React from "react";
import "../../pages/Custom.css";
import Authheader from "../../component/Authheader";
import Form from "react-bootstrap/Form";
import logo from "../../asset/images/logo.png";
import profileimg from "../../asset/images/profile-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FaCamera } from "react-icons/fa";

export const HostProfile = () => {
  return (
    <>
      <Authheader />
      <section className="host_profile_section">
        <div className="card container_custom box_shadow border_radius py-5 px-2 margin_100">
      <h2 className="text-center mb-3">User Profile</h2>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center text-center">
                  <div>
                    <div className="img_main_div">
                      <img
                        className="w-50 text-center"
                        src={profileimg}
                        alt=""
                      />
                      <h4>Image Name</h4>
                      <div className="camera_main_div">
                        <input type="file" className="update-img " />
                        <i>
                          <FontAwesomeIcon icon={faCamera} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-5 ">
                  <div>
                    <label>First Name</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Last Name</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Email</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Phone Number</label>
                    <br />
                    <input
                      className="form-control input_focus"
                      type="number"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="mt-3">
                    <button type="button" className="btn btn-dark w-100 py-2">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </>
  );
};

export default HostProfile;
