import { Delete, Search, Style, Update } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import Searchapp from "../../component/Search";
import SideBar from "../../component/SideBar";
import MaterialTable from "material-table";
import { forwardRef } from "react";
// import Logo from "../../asset/images/logo1.png";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
// import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "../../utlilites/axios";
// import loader from "../../asset/images/GTA-loader.gif";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../asset/images/logo2.png";
import profile_pic from "../../asset/images/agent/04.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCamera, faList, faUser } from "@fortawesome/free-solid-svg-icons";
import Buttons from "../../component/Buttons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Listing = () => {
  const [gtaloader, setGtaLoader] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const sidebar = () => {
    // alert();
    setOpen(!open);
    // console.log(open, "...............true")
  };

  const navigate = new useNavigate();

  // const [columns, setColumns] = useState([
  //   { title: "S.NO", field: "id" },
  //   { title: "Name", field: "name" },
  //   {
  //     title: "property type",
  //     field: "property_type",
  //     initialEditValue: "initial edit value",
  //   },
  //   { title: "check in date", field: "Checkindate" },
  //   {
  //     title: "check out date",
  //     field: "checkoutdate"
  //   },
  //   {
  //     title: "address",
  //     field: "address",
  //   },
  // ]);

  // const [data, setData] = useState([
  //   { id:1  ,name: "one bedroom", property_type: "Room", Checkindate: "10/11/1987", checkoutdate: "10/11/1988", address: "torronto" },
  //   { id:2  ,name: "Sea facing House", property_type: "home", Checkindate: "10/11/1987", checkoutdate: "10/11/1988", address: "albert" },
  //   { id:3  ,name: "luxary apartment", property_type: "apartment", Checkindate: "10/11/1987", checkoutdate: "10/11/1988", address: "ontario" },
  //   { id:4  ,name: "sing room", property_type: "Room", Checkindate: "10/11/1987", checkoutdate: "10/11/1988", address: "Montreal" },
  //   { id:5  ,name: "wood House", property_type: "House", Checkindate: "10/11/1987", checkoutdate: "10/11/1988", address: "Calgary" },
  // ]);
  // const userID = localStorage.getItem("user");
  //delete API
  // const { Id } = useParams();

  // const deldata = (rowData) => {
  //   try {
  //     const token = localStorage.getItem("accesstoken");
  //     var config = {
  //       method: "get",
  //       url: `http://gta.developer-hi.xyz/api/landlord/delete/property/${rowData.id}`,
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     axios(config).then(function (response) {
  //       setData(response.data);

  //       console.log(response.data, "wworking");
  //       // .catch(function (error) {
  //       //   console.log(error);
  //       // });
  //       swal({
  //         title: "Are you sure?",
  //         text: "Once deleted, you will not be able to recover this property!",
  //         icon: "warning",
  //         buttons: true,
  //         dangerMode: true,
  //       }).then((willDelete) => {
  //         if (willDelete) {
  //           swal("Poof! Your imaginary file has been deleted!", {
  //             icon: "success",
  //           });
  //         } else {
  //         }
  //       });
  //     });
  //   } catch (error) {}
  // };

  //delete API

  // const [api, setApi] = useState();
  // const details = () => {
  //   const token = localStorage.getItem("accesstoken");
  //   var config = {
  //     method: "get",
  //     url: `http://flexrentaladmin.sasstechnologies.com/api/listning/${userID}`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setApi(response.data.property);
  //       console.log(response.data.property, "profile");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  // console.log("api", api);
  // useEffect(() => {
  //   details();
  // }, []);

  // Sidebar apis

  const token = localStorage.getItem("accesstoken");
  const [datas, setDatas] = useState([]);
  // const [profile, setProfile] = useState([]);
  const listing = () => {
    var config = {
      method: "get",
      url: "https://flexrentaladmin.sasstechnologies.com/api/host/listning",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setDatas(response.data.listning);
        // setProfile(datas[0]?.user?.image);
        // setDatas(response.data.listning);

        // console.log(response.data.listning, "api check");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    listing();
  }, []);
  const Navigate = useNavigate();

  const [data, setData] = useState();
  const deldata = (rowData) => {
    setLoader(true);
    try {
      const token = localStorage.getItem("accesstoken");
      var config = {
        method: "get",
        url: `http://flexrentaladmin.sasstechnologies.com/api/delete/listning/${rowData.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config).then(function (response) {
        setData(response.data);
        if (response.data.status === true) {
          setLoader(false);
        }

        console.log(response.data, "wworking");
        // .catch(function (error) {
        //   console.log(error);
        // });
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this property!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            swal("Poof! Your list has been deleted!", {
              icon: "success",
            });
            window.location.reload();
          } else {
          }
        });
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const [profiledata, setProfileData] = useState();
  const [profileimage, setProfileImage] = useState();
  const profile = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: "https://flexrentaladmin.sasstechnologies.com/api/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        // setDatas(response.data.listning);
        setProfileData(response.data[1]);
        setProfileImage(response.data[1].image, "profile API chekc");
        // setDatas(response.data.listning);

        console.log(response.data, "api check");
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);

  const logout = () => {
    // alert("");
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Host Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    navigate("/hostlogin");
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  // SideBar Apis end

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(true);
  return (
    <>
    <section style={{ }}>
      {loader ? <div className="loader"></div> : null}
      <div className="row">
        <div className="col-lg-12">
      
            <Navbar
              bg="dark"
              className="h-100"
              style={{ visibility: "visible" }}
            >
              <Container>
                <Navbar.Brand href="#" className="w-50">
                  <img src={Logo} alt="" className="img-fluid" />
                </Navbar.Brand>
                {profileimage ? (
                  <img
                    src={`http://flexrental.dev-um.xyz/storage/${profileimage}`}
                    className="profileheader_img"
                    alt=""
                  />
                ) : (
                  <img src={profile_pic} className="profileheader_img" alt="" />
                )}
                <Nav.Link href="#action1" className="text-light text-center">
                  {profiledata?.first_name} {profiledata?.last_name}
                </Nav.Link>
                <div
                  className=""
                  onClick={handleShow}
                >
                  <FontAwesomeIcon icon={faBars} />
                </div>
                  
                <Offcanvas show={show} onHide={handleClose} backdrop="static" className="bg-dark">
                  <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                      className="w-75"
                    >
                      <img src={Logo} alt="" className="img-fluid" />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-center">
                      {profileimage?
                      <img src={`http://flexrental.dev-um.xyz/storage/${profileimage}`} className="profile_img" alt=""/>
                      :
                       <img src={profile_pic} className="profile_img" alt=""/> 
}
                      <Nav.Link
                        href="#action1"
                        className="text-light text-center">{profiledata?.first_name} {profiledata?.last_name}</Nav.Link>
                       <Link
                        to="/hostprofile"
                        className="text-light mb-2 ps-4 bg-primary round py-2"
                      >
                        <FontAwesomeIcon icon={faUser} /> &nbsp; &nbsp; Profile
                      </Link>
                      <hr className="mb-5" />

                      <Link
                        to="/hostdashboard"
                        className="text-light mb-2 ps-4 bg-primary round py-2"
                      >
                        <FontAwesomeIcon icon={faList} /> &nbsp; &nbsp; Listing
                      </Link>
                      <Link
                        to="/welcome"
                        className="text-light mb-5 ps-4 bg-primary round py-2"
                      >
                        <FontAwesomeIcon icon={faList} /> &nbsp; &nbsp; Add
                        Listing
                      </Link>
                      <Buttons name="Logout" onClick={logout} />
                    </Nav>
                  </Offcanvas.Body>
                </Offcanvas>
              </Container>
            </Navbar>
          
        </div>
      </div>

      <div className="container height">
        <div className="row">
          {/* <div className="col-md-1"></div> */}

          <div
            className={
              open === true
              ? "col-md-10 ms-14 my-3 ps-5 trans"
              : "col-md-10 margin-200px  my-3"
            }
          >
            <div className="card shadow ">
              <MaterialTable
                style={{ margin: "0px", padding: "0px" }}
                icons={tableIcons}
                title="Listing"
                columns={[
                  {
                    title: "S.NO",
                    field: "id",
                    cellStyle: { width: "1%" },
                  },
                  {
                    title: "Tittle",
                    field: "title",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Property Type",
                    field: "property_type",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Check-In",
                    field: "check_in",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Check-out",
                    field: "check_out",
                    cellStyle: { width: "20%" },
                  },
                  {
                    title: "Address",
                    field: "address",

                    cellStyle: { width: "20%" },
                  },
                ]}
                data={datas}
                actions={[
                  // {

                  //   icon: () => <Edit style={{ color: "white" }} />,
                  //   tooltip: "Edit Property",
                  //   onClick: (value, rowData) => {
                  //     navigate("/editlisting/" + rowData.id);
                  //     console.log(rowData.id, "object");
                  //   },
                  // },
                  {
                    icon: () => <Delete style={{ color: "red" }} />,
                    tooltip: "Delete Property",
                    onClick: (value, rowData) => {
                      deldata(rowData);
                    },
                  },
                ]}
                options={{
                  rowStyle: {
                    backgroundColor: "#212529",
                    color: "#fff",
                  },
                  actionsColumnIndex: -1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};
export default Listing;
