import React from "react";
import "../pages/Custom.css";


export const Authfooter = () => {
  return (
    <div>
      
          <div className="container-fluid bg-dark footerposition py-3">
            <div className="row align-items-center">
              <div className="offset-md-4 col-md-4 text-center">
                <p className="mb-0 text-white">
                  &copy;Copyright
                  <span id="copyright">
                    <script>
                      document.getElementById('copyright').appendChild(document.createTextNode(new
                      Date().getFullYear()))
                    </script>
                  </span>
                  <a href="#" className="text-white">
                    {" "}
                     Flex-Rental{" "}
                  </a>{" "}
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
     
  );
};

export default Authfooter;
