

import { createContext, useState } from 'react';
// hooks

// ----------------------------------------------------------------------

const initialState = {
  searchDetails: [],
  update: () => {},
};



const SEARCH = createContext(initialState);

// ----------------------------------------------------------------------



function SearchProvider({ children }) {
  const [searchDetails, setSearchDetails] = useState([]);

  const update = (a) => {
    setSearchDetails(a);
    console.log(a ,"update")
  }


  return (
    <SEARCH.Provider
    value={{
      searchDetails:searchDetails,
      update: update,
    }}
    >
      {children}
    </SEARCH.Provider>
  );
}

export { SearchProvider, SEARCH };