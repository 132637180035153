import { faAlignLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
// import Logo from "../asset/images/logo.png";
import newlogo from "../asset/images/new_logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utlilites/axios";
import swal from "sweetalert";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import '../pages/Custom.css';
import Buttons from "../component/Buttons";

export const Header = () => {
  // const Signin = "Sign In";

  //states

  // const [data, setData] = useState(null);

  //states

  //Api Fetching
  const Navigate = useNavigate();

  const token = localStorage.getItem("accesstoken");
  const [profiledata, setProfileData] = useState();
  const [profileimage, setProfileImage] = useState();
  const profile = () => {
    var config = {
      method: "get",
      url: "https://flexrentaladmin.sasstechnologies.com/api/profile",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.user, "profile API chekc");
        setProfileData(response.data.user);
        setProfileImage(response.data[1].image);
        // setDatas(response.data.listning);

        console.log(token, "api check");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    profile();
  }, []);

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/userlogin");
  };

  //Api Fetching

  return (
    <>
      <div className="header">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-transparent"
          variant="white"
        >
          <Container fluid>
            <div className="left left_div">
              <h1 className="invisible">Flex Rental</h1>
            </div>
            <div className="center">
              <Link to="/">
                <img src={newlogo} alt="" width={"20%"} className="new_logo" />
              </Link>
            </div>
            <div className="right">
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="toggle"
              >
                <FontAwesomeIcon icon={faAlignLeft} className="text-white" />
              </Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav">
                {/* <Nav className="ms-auto"></Nav> */}
                <Nav className="ms-auto">
                  {token ? null : (
                    <Nav.Link className="">
                      <Link to="/Hostsignup">
                        <Buttons name="Become a Host" />
                      </Link>
                    </Nav.Link>
                  )}

                  {!token ? (
                    <Nav.Link className="">
                      <Link to="/userlogin">
                        <Buttons name="Sign In" />
                      </Link>
                    </Nav.Link>
                  ) : (
                    <>
                    <div className="d-flex justify-content-center align-items-center">
                      <h4 className="text-light">
                        {profiledata?.first_name}&nbsp;
                        {profiledata?.last_name}
                      </h4>
                      <Link className="btn btn-theme ms-5" onClick={logout}>
                        Logout
                      </Link>
                    </div>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;

{
  /* <NavDropdown
  className=""
  id="collasible-nav-dropdown"
>
  {data === null ? (
    <NavDropdown.Item className="active pl-50">
      <Link to="/userlogin" className="">
        Sign in
      </Link>
    </NavDropdown.Item>
  ) : (
    <NavDropdown.Item className="active">
      <p className="">
        {data?.admin_profile?.email}
      </p>
    </NavDropdown.Item>
  )}
  <hr className="position" />{" "}
  <NavDropdown.Item className="active pl-50">
    <a className="" href="#" onClick={logout}>
      logout
    </a>
  </NavDropdown.Item>
</NavDropdown> */
}
