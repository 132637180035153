import React, { useContext, useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// import GoogleMapReact from 'google-map-react';
import Maps from "../../component/Maps";
import property1 from "../../asset/images/property/big-img-01.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowDown, FaSearch } from "react-icons/fa";
import {
  faBathtub,
  faBed,
  faCalendar,
  faLocation,
  faLocationArrow,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Slider } from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import Detailheader from "../../component/Detailheader";
import DetailSearch from "../../component/DetailSearch";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../Custom.css";
// import required modules
import axios from "../../utlilites/axios";
import swal from "sweetalert";
import { SEARCH } from "../../context/Searchcontext";
const AllProperty = ({ query }) => {
  const { searchDetails, setSearchDetails } = useContext(SEARCH);
  console.log(searchDetails, "context");
  const { id } = useParams();
  //States
  const [loader, setLoader] = useState(false);
  const [allproperty, setAllproperty] = useState();
  // const [cid, setCid] = useState();

  const navigate = new useNavigate();

  // const filter = () => {
  //   try {

  //     const filteration = new FormData();

  //     var config = {
  //       method: "POST",
  //       url: `http://gta.developer-hi.xyz/api/search/property/advance`,
  //       data: filteration,
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   Authorization: `Bearer ${tokens}`,
  //       // },
  //     };

  //     axios(config)
  //       .then(function (response) {
  //         setAllproperty(response.data.property);
  //         // console.log(response.data, "filter......");
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   } catch (error) {
  //     console.log(error )
  //   }
  // };

  // useEffect(()=> {
  //   filter();
  // },[])

  // function contactid(id) {
  //   const token = localStorage.getItem("accesstoken");
  //   var config = {
  //     method: "get",
  //     url: `http://gta.developer-hi.xyz/api/detail/property/${id}`,
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setLoader(true);
  //       console.log(response.data.property, "propertydetails");
  //       setDetails(response.data.property);
  //       setAddres(response.data.property?.address);
  //       setPrice(response?.data?.property?.floor?.map(f => f.rent))
  //       // {response.data.property.floor.map((f)=>{
  //       // setPrice(f.rent);
  //       // })}
  //       setProprty(response.data.property.property_type);
  //       // setCid(response.data.property.id);
  //       setLoader(false);
  //       if (response.status) {
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // const contactapi = () => {
  //   const contct = new FormData();
  //   contct.append("property_id", cid);
  //   contct.append("name", name);
  //   contct.append("address", addres);
  //   contct.append("phone_number", number);
  //   contct.append("email", email);
  //   contct.append("price", price);
  //   console.log(price,"..................")
  //   contct.append("property_type", proprty);
  //   contct.append("message", message);
  //   // const token = localStorage.getItem("accesstoken");f
  //   var config = {
  //     method: "post",
  //     url: `http://gta.developer-hi.xyz/api/contactus/property`,
  //     data: contct,
  //     headers: {
  //       Accept: "application/json",
  //       // Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setLoader(true);
  //       setApi(response.data);
  //       setStatus(response.data.status);

  //       // setPath(response.data.imagepath);
  //       console.log(response, "checking");
  //       // console.log(response.data.imagepath, "path");
  //       if (response.status) {
  //         swal({
  //           title: "Good job!",
  //           text: response.data.message,
  //           text: "your message send successfuly",
  //           icon: "success",
  //           button: "Ok",
  //         });
  //         navigate("/AllProperty");
  //       } else {
  //       }
  //       if (response.status) {
  //         setLoader(false);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const property = () => {
  //   setLoader(true);

  //   // const token = localStorage.getItem("accesstoken");
  //   var config = {
  //     method: "get",
  //     url: "https://flexrentaladmin.sasstechnologies.com/api/listning",
  //     headers: {
  //       Accept: "application/json",
  //       // Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response, "checking");
  //       if (response.status) {
  //         setAllproperty(response.data.listnings);
  //         setLoader(false);
  //       } else {
  //         setAllproperty(["no result found"])
  //       }
  //       // setLoader(false);
  //     })
  //     .catch(function (error) {
  //       setLoader(false);

  //       console.log(error);
  //     });
  // };
  // console.log("api", allproperty);

  // useEffect(() => {
  //   property();
  // }, []);

  // useEffect(()=>{
  //   setAllproperty(localStorage.getItem("search"))
  // },[])
  useEffect(() => {
    setAllproperty(searchDetails);
  }, [searchDetails]);
  console.log(allproperty, "=========");
  return (
    <>
      <Detailheader />
      {loader ? <div className="loader"></div> : null}
      <section className="">
        <div className="container mb-3">
          <div className="row">
            <div className="col-lg-12 pt-3">
              <DetailSearch classna="text-dark" />

              {/* card 1 */}
              {allproperty?.map((e) => {
                return (
                  <>
                    <div className="row">
                      <div className="offset-lg-2 col-lg-8 col-md-8 col-sm-12 mb-1">
                        <div>
                          <div className="card mt-1 box_shadow ">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="">
                                  <img
                                    src={`http://flexrental.dev-um.xyz/storage/${e?.cover_photo}`}
                                    className=""
                                    width={"300px"}
                                    height={"300px"}
                                  />
                                </div>
                              </div>
                              <div className="col-md-7 d-flex align-items-center">
                                <div
                                  onClick={() =>
                                    navigate("/listingdetail" + e.id)
                                  }
                                >
                                  <div className="py-3 ps-1 d-flex flex-column">
                                    <p >
                                      <span className="h4">{e?.rate_per_night} $ &nbsp; </span>
                                      Rate Per Night
                                    </p>
                                    <h5>{e?.address}</h5>
                                    <span>
                                      <FontAwesomeIcon icon={faBed} />
                                      &nbsp;
                                      <span className="fw-bolder">
                                      Bedrooms  {e?.no_of_bedrooms}  |
                                        &nbsp;&nbsp;
                                      </span>
                                      <FontAwesomeIcon icon={faBathtub} />
                                        &nbsp;&nbsp;
                                      <span className="fw-bolder">
                                        Bathrooms {e?.no_of_bathrooms}{" "} |
                                      </span>
                                    
                                      &nbsp;&nbsp;
                                      <FontAwesomeIcon icon={faUser} />
                                      &nbsp;&nbsp;
                                      <span className="fw-bolder">
                                        Guest {e?.no_of_guest_allowed}
                                        &nbsp;&nbsp;
                                      </span>
                                    </span>
                                    <h4 className="mt-2">{e?.title}</h4>
                                    <div className="d-flex justigy-content-center align-items-center">
                                      <img
                                        src={`http://flexrental.dev-um.xyz/storage/${e?.user?.image}`}
                                        className="searchImg"
                                      />
                                      <p className="text-secondary fs-6 ms-3">
                                        {e?.user?.first_name}{" "}
                                        {e?.user?.last_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12  col-md-12">
              {searchDetails?.map((e) => {
                return (
                  <>
                    <div onClick={() => navigate("/propertydetail" + e.id)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="property">
                            <div className="slider mt-1 ">
                              <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                              >
                                {e.photos.map((x) => (
                                  <SwiperSlide>
                                    <img
                                      src={`http://flexrental.dev-um.xyz/storage/${x.image}`}
                                      alt=""
                                    />
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card p-3 br-theme">
                            <div className="row">
                              <div className="col-lg-9">
                                <h5>
                                  {e.property_type}
                                  <span className="me-1 ms-5">
                                    <FontAwesomeIcon icon={faLocationArrow} />
                                  </span>
                                  {e.address}
                                </h5>
                                <h4>${e.rate_per_night}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default AllProperty;
