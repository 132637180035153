import Authfooter from "../../component/Authfooter";
import Authheader from "../../component/Authheader";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import swal from "sweetalert";
import logo from "../../asset/images/new_logo.png"

export const Userlogin = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const Navigate = useNavigate();
  const loginfunc = async (V) => {
    try {
      const response = await axios.post("/login", V);
      const { message, status, token } = response.data;
      if (status == true) {
        setSession(token);
        swal({
          title: "Good job!",
          text: "Rental Login SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        Navigate("/");
      } else {
        Navigate("/userlogin");
      }
    } catch (error) {
      console.log(error?.message);
      swal({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };
//   const token = localStorage.getItem("accesstoken");
//   useEffect(()=>{
//     console.log(token)
// if(token){
//  Navigate("/");
// }else{
//   Navigate("/userlogin")
// }
//   },[]) 

  return (
    <section className="bg-login h-100vh" style={{backgroundColor: "#000"}}>
      <img className="text-center mx-auto d-block" width={"10%"} src={logo} alt="" />
      <Formik
        initialValues={{
          email: "",
          password: "",
          role: "customer",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          // same shape as initial values
          loginfunc(values);
        }}
      >
        {({ errors, touched }) => (
            <div className="container-fluid p-2">
          <Form>
              <div className="row">
                <div className="offset-lg-4 col-lg-4">
                  <div className="card shadow border-radious bg-card">
                    <div className="card-header">
                      <h4 className="text-light fw-bolder text-center">
                        Welcome To Login
                      </h4>
                    </div>
                    <div className="card-body">
                      <label className="form-label fw-bolder fs-6 text-white">
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="col-lg-12 form-control mb-3"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                      <label className="form-label fw-bolder fs-6 text-white">
                        Password
                      </label>
                      <Field
                        name="password"
                        type="password"
                        className="col-lg-12 form-control"
                        placeholder="Enter Your Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                      <div className="row mt-2">
                        <div className="col-lg-7"></div>
                        <div className="col-lg-5">
                          <Link to="/userforgot">Forgot Password ?</Link>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-light offset-lg-3 col-lg-6 mt-1"
                      >
                        Log In
                      </button>

                      <div className="col-lg-12 text-center mt-2">
                        <p className="text-light">
                          If You Don't Have Account ?
                          <Link to="/usersignup" className="text-white">
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Form>
            </div>
        )}
      </Formik>
    </section>
  );
};

export default Userlogin;
