import { faAlignLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
// import Logo from "../asset/images/logo2.png";
import newlogo from "../asset/images/new_logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "../utlilites/axios";
import swal from "sweetalert";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Buttons from "./Buttons";
// import '../pages/Custom.css';

export const Detailheader = () => {

 //Api Fetching
 const Navigate = useNavigate();

 const token = localStorage.getItem("accesstoken");
 const [profiledata, setProfileData] = useState();
 const [profileimage, setProfileImage] = useState();
 const profile = () => {
   var config = {
     method: "get",
     url: "https://flexrentaladmin.sasstechnologies.com/api/profile",
     headers: {
       Accept: "application/json",
       Authorization: `Bearer ${token}`,
     },
   };

   axios(config)
     .then(function (response) {
       // setDatas(response.data.listning);
       setProfileData(response.data[1]);
       setProfileImage(response.data[1].image, "profile API chekc");
       // setDatas(response.data.listning);

       console.log(response.data, "api check");
     })
     .catch(function (error) {
       console.log(error);
     });
 };
 useEffect(() => {
   profile();
 }, []);

 const logout = () => {
   localStorage.clear();
   swal({
     title: "Good job!",
     text: "Logout SuccessFully!",
     icon: "success",
     button: "Ok",
   });
   Navigate("/userlogin");
 };

 //Api Fetching
 
  return (
    <>
      <div className="detailheader">

      <Navbar
          collapseOnSelect
          expand="lg"
          className="bg-dark"
          variant="white"
        >
      <Container fluid>

              <div className="left left_div">
                <h1 className="invisible">Flex Rental</h1>
              </div>
              <div className="center">
                <Link to="/">
                  <img src={newlogo} alt="" width={"15%"} className="new_logo"/>
                </Link>
              </div>
              <div className="right">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="toggle">
                      <FontAwesomeIcon
                        icon={faAlignLeft}
                        className="text-white"
                      />
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                      {/* <Nav className="ms-auto"></Nav> */}


                      <Nav className="ms-auto">
              

              {!token ? (
                  <Nav.Link className="">
                    <Link to="/userlogin">
                      <Buttons name="Sign In" />
                    </Link>
                  </Nav.Link>
                ) : (
                  <NavDropdown
                    className="text-white pt-2"
                    title={profiledata?.email}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item className="bg-light px-1 py-3 w-50 rounded">
                      <a
                        className="btn btn-theme mx-auto w-50 d-block"
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </NavDropdown.Item>
                    {/* <NavDropdown.Divider /> */}
                  </NavDropdown>
                )}
              </Nav>
                    </Navbar.Collapse>
              </div>
      </Container>

        </Navbar>










       
      </div>
    </>
  );
};

export default Detailheader;

{
  /* <NavDropdown
  className=""
  id="collasible-nav-dropdown"
>
  {data === null ? (
    <NavDropdown.Item className="active pl-50">
      <Link to="/userlogin" className="">
        Sign in
      </Link>
    </NavDropdown.Item>
  ) : (
    <NavDropdown.Item className="active">
      <p className="">
        {data?.admin_profile?.email}
      </p>
    </NavDropdown.Item>
  )}
  <hr className="position" />{" "}
  <NavDropdown.Item className="active pl-50">
    <a className="" href="#" onClick={logout}>
      logout
    </a>
  </NavDropdown.Item>
</NavDropdown> */
}
