import React from "react";
import { Link } from "react-router-dom";
// import property from "../../public/asset/images/property/list/01.jpg";
// import property1 from "../../public/asset/images/property/list/02.jpg";
// import property2 from "../../public/asset/images/property/list/03.jpg";

export const Footer = () => {
  return (
    <div>
      <footer className="footer  bg-black">
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 text-center">
                <p className="mb-0 text-white">
                  Copyright &copy;
                  <span className="text-white">
                     2021
                  </span>
                  <a href="#" className="text-white">
                    {" "}
                    Flex-Rentals{" "}
                  </a>{" "}
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
