import React, { useEffect, useState } from "react";
// import swal from "sweetalert";

import loaders from "../asset/images/Flexloader.gif";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Searchapp from "../component/Search";
import axios from "../utlilites/axios";
import "./Custom.css";
import DetailSearch from "../component/DetailSearch";
import Card from "../component/Card";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation } from "@fortawesome/free-solid-svg-icons";

import { Bars } from 'react-loader-spinner'
// import Skeleton from 'react-loading-skeleton'

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const Home = () => {
  const navigate = new useNavigate();

  //useStates

  const [loaders, setLoaders] = useState(false);
  const [loader, setLoader] = useState(true);
  const [getproperty, setGetproperty] = useState();
  const [path, setPath] = useState();

  //useStates

  //All property API fetching

  const property = () => {
    setLoader(true);
    
    // const token = localStorage.getItem("accesstoken");
    var config = {
      method: "get",
      url: "https://flexrentaladmin.sasstechnologies.com/api/listning",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "checking");
        if (response.status) {
          setGetproperty(response.data.listnings);
          setPath(response.data.imagepath);
          setLoader(false);
          setLoaders(false);
        } else {
          setGetproperty(["no result found"])
        }
        // setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);

        console.log(error);
      });
  };
  console.log("api", getproperty);

  useEffect(() => {
    setLoaders(true);
    property();
  }, []);
  // setLoaders(false);

  // All property API fetching
  setTimeout(() => {
    setLoaders(false)
  }, 3000);

  // const [api, setApi] = useState("")
  console.log(getproperty, "searching ================")
  return (
    <>
      {loaders ? <div className="loader"></div> : null}
      <Header />

      {/* :<> */}
      <section className="bg-holder bg-overlay-black-30 banner-1" >
        <div className="container">
          <div className="row">
            <div className="col-12 position-relative margin_top">
              <h1 className="text-white text-center mb-5 font fw-bolder">
                Short Term Rentals Across The Globe
              </h1>
              {/* <p className="lead text-center text-white mb-4 fw-normal font">
                Thousands of apartments, houses, and condos <br /> for rent across
                Canada
              </p> */}

              <section>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <DetailSearch classna = "text-light" setApi={setGetproperty}/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <section className="home-pb bg-black">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center my-5">
                <h2 className="text-white">Our Listings</h2>
                {/* <p>Find your next apartment for rent anywhere in Canada</p> */}
              </div>
            </div>
          </div>

          <div className="row">
            {/* <Card /> */}
            {getproperty?.map((i) => {
              return (
                <div className="col-sm-6 col-md-3">
                  <div className="property-item">
                    <div onClick={() => navigate("/listingdetail" + i.id)}>
                      <div className="property-image bg-overlay-gradient-04">
                        {/* {i.property_image.slice(0, 1).map((x) => ( */}
                        <img
                          className="object_fit_cover home_card_img"
                          src={`http://flexrental.dev-um.xyz/storage/${i.cover_photo}`}
                          alt="NO image Found "
                          width={"100%"}
                          height={174.6}
                        />
                        {/* ))} */}
                      </div>
                      <div className="property-details bg-dark text-light">
                        <div className="property-details-inner">
                        <h5 className="property-title text-light">
                            {i.property_type}
                          </h5>
                          <h5 className="property-title text-light">
                            {i.address}
                          </h5>
                          <h5 className="property-title text-light">
                            {i.kind_space}
                          </h5>
                          {/* <span className="property-address">
                            10 KM Away
                          </span> */}

                          <span className="property-address">
                            ${i.rate_per_night} Per Night
                          </span>
                          <br />
                          {/* {i.floor.map((x) => {
                          return (
                            <span className="property-agent-date">
                              {x.availability}
                            </span>
                          );
                        })}
                        <div className="property-price">
                          $
                          {i.floor.map((x) => {
                            return <>{x.rent}</>;
                          })}
                          <span> / month</span>
                        </div>
                        <ul className="property-info list-unstyled d-flex">
                          <li className="flex-fill property-bed">
                            {i.floor.map((x) => {
                              return (
                                <>
                                  Bed<span>{x.bedroom}</span>
                                </>
                              );
                            })}
                          </li>
                          <li className="flex-fill property-bath">
                            {i.floor.map((x) => {
                              return (
                                <>
                                  Bath<span>{x.bathroom}</span>
                                </>
                              );
                            })}
                          </li>
                          <li className="flex-fill property-m-sqft">
                           sqft
                            {i.floor.map((x) => {
                              return (
                                <>
                                  <span>{x.unit_size}m</span>
                                </>
                              );
                            })}
                          </li>
                        </ul> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          
        </div>
      </section>

      {/* <section className="mt-0 mb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5"></div>
            <div className="col-lg-4 ">
              <Link to="/AllProperty" className="btn btn-outline-success btn-lg">
                See More......
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Home;
