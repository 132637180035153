import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setSession } from "../../utlilites/jwt";
import Swal from "sweetalert2";
import axios from "../../utlilites/axios";
import logo from "../../asset/images/new_logo.png";
import { SettingsSystemDaydreamOutlined } from "@mui/icons-material";

export const Hostlogin = () => {
  const [loader, setLoader] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });
  const Navigate = useNavigate();
  const landlogin = async (V) => {
    setEmail(V.email);
    setLoader(true);
    try {
      const response = await axios.post("/login", V);
      console.log(response, "working");
      const { message, status, token } = response.data;
      if (status == true) {
        setSession(token);
        Swal.fire({
          title: "Good job!",
          text: "Host Login SuccessFully!",
          icon: "success",
          button: "Ok",
        });
        Navigate("/welcome");
        setLoader(false);
      } else {
      }
    } catch (error) {
      setLoader(false);
      console.log(error?.message);
      Swal.fire({
        title: "Something Went Wrong",
        text: error?.message,
        icon: "error",
        dangerMode: true,
        confirmButtonText: "reset",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(result, "hello ");
          resetapi(V.email);
        } else {
        }
      });
    }
  };

  const [email, setEmail] = useState();
  const [resetApi, setResetApi] = useState();
  console.log(email, "");
  const resetapi = (e) => {
    console.log("resetapi ", email);
    setLoader(true);
    try {
      const reset = new FormData();
      reset.append("email", e);

      var config = {
        method: "POST",
        url: `https://flexrentaladmin.sasstechnologies.com/api/reset/verification`,
        data: reset,
        headers: {
          Accept: "application/json",
          // Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          setResetApi(response);
          console.log(response, "reset api");
          if (response.data.status === true) {
            setLoader(false);
            Swal.fire({
              title: "Good job! ",
              text: "",
              icon: "success",
              button: "Ok",
            });
            // Navigate("/hostdashboard");
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <section
      className="bg-login"
      style={{ backgroundColor: "#000", height: "120vh"}}
    >
      {loader ? <div className="loader"></div> : null}
      {/* <Authheader /> */}
      <div>
        <img
          className="text-center mx-auto d-block"
          width={"15%"}
          src={logo}
          alt=""
        />
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
          role: "host",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          // same shape as initial values
          landlogin(values);
        }}
      >
        {({ errors, touched }) => (
          <div className="container-fluid p-2 mt-2 py-5">
            <Form>
              <div className="row">
                <div className="offset-lg-4 col-lg-4">
                  <div className="card bg-card">
                    <div className="card-header">
                      <h2 className="text-light fw-bolder text-center">
                        Login to your Profile
                      </h2>
                    </div>
                    <div className="card-body">
                      <h4 className="form-label fw-bolder text-white">Email</h4>
                      <Field
                        name="email"
                        type="email"
                        className="col-lg-12 form-control mb-3 py-4"
                        placeholder="Enter Your Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                      <h4 className="form-label fw-bolder text-white">
                        Password
                      </h4>
                      <Field
                        name="password"
                        type="password"
                        className="col-lg-12 form-control py-4"
                        placeholder="Enter Your Password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                      <div className="row mt-2 text-end">
                        {/* <div className="col-lg-7"></div> */}
                        <div className="col-lg-12">
                          <Link to="/hostforgot">Forgot Password ?</Link>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            className="btn btn-theme mt-2 fs-5"
                          >
                            Login
                          </button>
                        </div>
                      </div>

                      <div className="col-lg-12 text-center">
                        <p className="text-light mt-2">
                          If You Don't Have Flex-Rental Account ?{" "} <br/>
                          <Link to="/Hostsignup" className="text-white">
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            
          </div>
        )}
      </Formik>

      {/* <Authfooter /> */}
    </section>
  );
};

export default Hostlogin;
