import React from "react";

export const Buttons = ({name,...others}) => {
  return (
    <>
      <button className="btn btn-theme btn-block  xsmall" {...others}>
      {name}
      </button>
    </>
  );
};

export default Buttons;
