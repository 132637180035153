import { useParams } from "react-router-dom";
import {
  faL,
  faLocation,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Dropzone from "./Dropzone";
import "../../pages/host/editor.css";
import { Editor } from "react-draft-wysiwyg";
import { Handshake } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "../../utlilites/axios";
import { setSession } from "../../utlilites/jwt";
import { Button } from "react-bootstrap";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { set } from "immutable";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../asset/images/logo2.png";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Buttons from "../../component/Buttons";
import ImageUploading from "react-images-uploading";

const Edit_listing = () => {

  const [loader, setLoader] = useState(false);
  //check in check out work
  const ref = useRef();
  const refs = useRef();
  //check in check out work

  //single image upload work

  const [images, setImages] = React.useState();
  const [image, setImage] = React.useState();
  const maxNumber = 69;
  const onChange = (imageList) => {
    setImage(imageList[0].file);

    // console.log(imageList[0].file.name, "...cover photo");
    // data for submit
    setImages(imageList);
  };

  console.log(images, "...cover photo name");

  //single image upload work

  //sidebar work
  const [open, setOpen] = useState(false);
  const sidebar = () => {
    // alert();
    setOpen(!open);
    // console.log(open, "...............true")
  };
  //dropzone work
  const [File, setFile] = useState();
  console.log(File, "====>file");

  const dropzoneStyle = {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  };

  //dropzone work

  //cloning work
  const [inputList, setinputList] = useState([
    {
      id: uuid(),
      single: "",
      double: "",
      queen: "",
      king: "",
      no_of_bedroom: "",
    },
  ]);
  const handleaddclick = () => {
    setinputList([
      ...inputList,
      {
        id: uuid(),
        single: "",
        double: "",
        queen: "",
        queen: "",
      },
    ]);
  };

  const removeclick = (index) => {
    const list = [...inputList];
    // console.log(index);
    list.splice(index, 1);
    setinputList([...list]);
  };

  const handleChangeInput = (floorid, floorevent) => {
    // console.log("a", floorid);
    // console.log("b", floorevent);

    const newInputField = inputList.map((i) => {
      if (floorid === i.id) {
        // console.log("daaaaaaaadaaaaaa123234213", i);
        i[floorevent.target.name] = floorevent.target.value;
        // console.log("id", floorid);
      }
      return i;
    });
    setinputList(newInputField);
    console.log(inputList, ".........bedroom");
  };
  //cloning work

  const handleChange = (e) => {
    console.log(e)
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setFacilities([...facilities, value]);
    } else {
      setFacilities(facilities.filter((e) => e !== value));
    }
    console.log(facilities, "checking.........");
  };
  //get checkbox values

  //add listing form states
  const [house, setHouse] = useState("");
  const [apart, setApart] = useState("");
  const [condo, setCondo] = useState("");
  const [town, setTown] = useState("");
  const [listingid, setListingid] = useState("");
  const [tittle, setTittle] = useState("");
  const [apartm, setApartm] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [bathroom, setBathroom] = useState("");
  const [price, setPrice] = useState("");
  const [summary, setSummary] = useState("");
  const [guest, setGuest] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [property_type, setProperty_type] = useState("");
  const [coverphoto, setCoverphoto] = useState("");
  //host info states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [Editors, setEditors] = useState("");
  const [html, setHtml] = useState("");
  console.log(Editors, "editor value checking......");
  const [checkindate, setCheckindate] = useState("");
  const [checkoutdate, setCheckoutdate] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditors(editorState);
    let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtml(data);
    console.log(html, "trext arera");
  };

  // Get data From Api
  const [editdata, setEditdata] = useState();
  const { id } = useParams();
  console.log(id, "====> id");

  const token = localStorage.getItem("accesstoken");
  const editapi = () => {
    setLoader(true)
    var config = {
      method: "get",
      url: `https://flexrentaladmin.sasstechnologies.com/api/listning/${id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setLoader(false)
        setEditdata(response.data.listning);
        console.log(response.data, "editproperty");
        setListingid(response.data.listning.id);
        setTittle(response.data.listning.title);
        setApartm(response.data.listning.property_type);
        setCity(response.data.listning.city);
        setCountry(response.data.listning.country);
        setAddress(response.data.listning.address);
        setBathroom(response.data.listning.no_of_bathrooms);
        setGuest(response.data.listning.no_of_guest_allowed);
        setSummary(response.data.listning.property_summary);
        setPrice(response.data.listning.rate_per_night);
        setFacilities(
          response.data.listning.facilities);
          
        setName(response.data.listning.host_name);
        setEmail(response.data.listning.host_email);
        setPhone(response.data.listning.host_phone);
        setCheckindate(response.data.listning.check_in);
        setCheckoutdate(response.data.listning.check_out);
        setCoverphoto(response.data.listning.cover_photo);
        const blocksFromHtml = htmlToDraft(
          response.data.listning.property_description
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditors(EditorState.createWithContent(contentState));
        // setEditors(response.data.listning.property_description)

        setinputList(
          response.data.listning.bed.map((e,i) => {
            const ind  = `no_of_bedroom${i}`
            return(
            {
            id: e.id,
            [ind]: e.bed_size,
            bed_size: e.bed_size,
            
          })})
        );
        console.log("BEDR0OMS", inputList);
        console.log(coverphoto, ".............");
      })
      .catch(function (error) {
        setLoader(false)
        console.log(error);
      });
  };
  console.log("api", editdata);

  useEffect(() => {
    editapi();
  }, []);

  // Update Api
  console.log(Editors, "editor value checking......");
  const [api, setApi] = useState();
  const tokens = localStorage.getItem("accesstoken");
  const Navigate = useNavigate();

   const add = () => {
    setLoader(true)
     console.log(inputList)
    //  if(File !== null && File.length !== 0){
    //    imageapi();
    //  }

     try {
       const updatelisting = new FormData();
      updatelisting.append("id", listingid);
      updatelisting.append("title", tittle);
      updatelisting.append("property_type", apartm);
      updatelisting.append("city", city);
      updatelisting.append("country", country);
      updatelisting.append("address", address);
      updatelisting.append("no_of_guest_allowed", guest);
      updatelisting.append("no_of_bathrooms", bathroom);
      updatelisting.append("property_summary", summary);
      updatelisting.append("rate_per_night", price);
      updatelisting.append("facilities[]", facilities);

      updatelisting.append("cover_photo", image);
  
       inputList.map((e, i) => {
        const bedroom = `no_of_bedroom${i}`;
        updatelisting.append(`no_of_bedroom[${i}]`, e[bedroom]);
        console.log("===========> avialability", e, i);
       });
       File.map((e, i) => {
        updatelisting.append(`photos[${i}]`, e);
        console.log(`photos[${i}]`, e);
      });
      //host info update 
      updatelisting.append("host_name", name)
      updatelisting.append("host_email", email)
      updatelisting.append("host_phone", phone)
      updatelisting.append("property_description", html);
      updatelisting.append("check_in", checkindate);
      updatelisting.append("check_out", checkoutdate);
      console.log(Editors, "description");
       var config = {
         method: "POST",
         url: `https://flexrentaladmin.sasstechnologies.com/api/update/listning`,
         data: updatelisting,
         headers: {
           Accept: "application/json",
           Authorization: `Bearer ${tokens}`,
         },
       };

       axios(config).then(function (response) {
         setApi(response.data.listning);
          const { message, sstatus, token } = response.data;
         if (response.data.status === true) {
          setLoader(false)
           swal({
             title: "Good job!",
             text: response.data.message,
             icon: "success",
             button: "Ok",
           });
           Navigate("/Hostdashboard");
         } else {

          setLoader(false)
         }
       });
     } catch (errors) {
      setLoader(false)
      //  console.log(errors, errors.errors, errors.errors, "chup");
       swal({
         title: "Something Went Wrong",
         text: errors.errors,
         icon: "error",
         dangerMode: true,
       });
     }
   };

  // SIde Bar Apis

  // const Navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    swal({
      title: "Good job!",
      text: "Rental Logout SuccessFully!",
      icon: "success",
      button: "Ok",
    });
    Navigate("/landlordlogin");
  };
  // Side bar apis end

  useEffect(() => {
    console.log(token);
    if (token) {
      console.log("logged in");
    } else {
      Navigate("/landlordlogin");
    }
  }, []);

  console.log( apartm, "radio button checked");

  const isChecked=(i,type ,x)=>{
    console.log(x)
      const bedroom1 = `no_of_bedroom${i}`
         console.log(x[bedroom1],"important")
       return x[bedroom1] === type 
  }
  return (
    <>
    {loader ? (<div className="loader"></div>) : (null)} 
      <div className="row">
        <div className="col-lg-12">
          {[false].map((expand) => (
            <Navbar key={expand} bg="dark" expand={expand} className="h-100">
              <Container>
                <Navbar.Brand href="#" className="w-50">
                  <img src={Logo} alt="" className="img-fluid" />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  className=""
                  onClick={() => sidebar()}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                  className="bg-dark"
                >
                  <Offcanvas.Header>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                      className="w-75"
                    >
                      <img src={Logo} alt="" className="img-fluid" />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-center">
                      <Nav.Link
                        href="#action1"
                        className="text-light text-center"
                      >
                        {editdata?.user?.first_name} {editdata?.user?.last_name}
                      </Nav.Link>
                      <hr className="mb-5" />
                      <Link
                        to="/hostdashboard"
                        className="text-light mb-2 ps-4 bg-primary round py-2"
                      >
                        <FontAwesomeIcon icon={faList} /> &nbsp; &nbsp; Listing
                      </Link>
                      <Link
                        to="/hostaddproperty"
                        className="text-light mb-5 ps-4 bg-primary round py-2"
                      >
                        <FontAwesomeIcon icon={faList} /> &nbsp; &nbsp; Add
                        Listing
                      </Link>
                      <Buttons name="Logout" onClick={logout} />
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>
      </div>

      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div
              className={
                open === true
                  ? "col-md-9 ms-15 trans"
                  : "offset-md-2 col-md-9 trans"
              }
            >
              <form>
                <div className="card shadow">
                  <div className="card-header d-flex justify-content-center">
                    <h2 className="text-black text-center">Edit Listing</h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-12 mb-3">
                        <label for="Property Type">Tittle</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your property tittle"
                          value={tittle}
                          onChange={(e) => setTittle(e.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label for="Property Type" className="mb-2">
                          Property Type
                        </label>
                        <div classsName="row mb-3">
                          <div className="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="house"
                              checked={apartm === "house" ? true : false}
                              onChange={(e)=>setApartm(e.target.value)} 
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              House
                            </label>
                          </div>
                          <div className="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="Apartment"
                              checked={apartm === "Apartment" ? true : false} 
                              onClick={(e)=>setApartm(e.target.value)} 
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Apartment
                            </label>
                          </div>
                          <div className="form-check form-check-inline col-md-2">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio3"
                              value="condo"
                              checked={apartm === "condo" ? true : false}
                              onChange={(e) => setApartm(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio3">
                              Condo
                            </label>
                          </div>
                          <div className="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio4"
                              value="Town House"
                              checked={apartm === "Town House" ? true : false}
                              onChange={(e) => setApartm(e.target.value)}
                            />
                            <label class="form-check-label" for="inlineRadio4">
                              TownHouse
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-md-6 mb-3">
                        <label for="city">City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label for="Country">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-12 mb-5">
                        <label for="address">Address</label>
                        <input
                          className="form-control"
                          type="text"
                          name="address"
                          tabindex="2"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>

                      <h4 className="text-black text-center">No of Bedrooms</h4>
                      {/* cloing */}
                      <div className="productdiv mb-5">
                        {inputList.map((x, i) => {
                          return (
                            <div className="row mt-4">
                              <div className="form-group col-md-1"></div>
                              <div className="form-group col-md-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"no_of_bedroom" + `${i}`}
                                    value="single"
                                    checked={isChecked(i ,"single",x)}
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="inlineRadio1"
                                  >
                                    Single
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    value="double"
                                    name={"no_of_bedroom" + `${i}`}
                                    checked={isChecked(i ,"double",x)}
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="inlineRadio2"
                                  >
                                    Double
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"no_of_bedroom" + `${i}`}
                                    value="Queen"
                                    checked={isChecked(i ,"Queen",x)}
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="inlineRadio3"
                                  >
                                    Queen
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"no_of_bedroom" + `${i}`}
                                    value="King"
                                    checked={isChecked(i ,"King",x)}
                                    onChange={(e) => handleChangeInput(x.id, e)}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="inlineRadio4"
                                  >
                                    King
                                  </label>
                                </div>
                              </div>

                              <div className="form-group col-md-2">
                                <div className="row">
                                  <div className="col-md-6 ">
                                    {inputList.length - 1 === i && (
                                      <button
                                        type="button"
                                        className="btn w-100 border text-success"
                                        title="Add"
                                        onClick={handleaddclick}
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </button>
                                    )}
                                  </div>
                                  {inputList.length !== 1 && (
                                    <div className="col-md-6 text-center ">
                                      <button
                                        type="button"
                                        title="remove"
                                        className="btn  text-danger border w-100 removebtn"
                                        onClick={removeclick}
                                      >
                                        <FontAwesomeIcon icon={faMinus} />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* cloing end */}

                      <div id="moreproduct"></div>

                      <div className="form-group col-md-6 mb-3">
                        <label for="no of bathroom">No of bathroom</label>
                        <input
                          type="number"
                          className="form-control"
                          value={bathroom}
                          onChange={(e) => setBathroom(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-6 mb-3">
                        <label for="no of Guest Allowed">
                          No of Guest Allowed
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          value={guest}
                          onChange={(e) => setGuest(e.target.value)}
                        />
                      </div>

                      <h4 className=" my-3  text-center">Photos</h4>
                      <div className="form-group col-md-12">
                        <label className="ms-5">Multiple files</label>
                        <Dropzone setFile={setFile} />
                      </div>

                      <h4 className="my-3  text-center">cover Photos</h4>
                      <div className="from-group col-md-12">
                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper w-100">
                              <button
                                type="button"
                                className="uplaod"
                                style={
                                  isDragging
                                    ? { color: "red", width: "100%" }
                                    : null
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </button>
                              &nbsp;
                              {/* <button onClick={onImageRemoveAll}>
                                Remove all images
                              </button> */}
                              {images ? (
                                imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img
                                      src={image.data_url}
                                      alt=""
                                      className="image"
                                    />
                                    <div className="image-item__btn-wrapper">
                                      <button
                                      type="button"
                                        className="u-r bg-dark text-white"
                                        onClick={() => onImageRemove(index)}
                                        // onClick={() =>alert('')}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="image-item">
                                    <img
                                  src={`http://flexrental.dev-um.xyz/storage/${coverphoto}`}
                                  className="image"
                                  // onChange={(e)=>setImg(e.target.value)}
                                />
                                </div>
                                
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>

                      <div className="form-group col-md-12 mt-3">
                        <h4 className="text-center my-3">Summary</h4>
                        <textarea
                          rows={8}
                          cols={100}
                          className="form-control border border-dark"
                          placeholder="Enter your Listing Description here..........."
                          value={summary}
                          onChange={(e) => setSummary(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="form-group col-md-6 mt-3">
                        <label for="Rate Per Night">Rate Per Night</label>
                        <input
                          type="number"
                          className="form-control"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 mt-3 mb-3">
                        <h4 className="text-center my-3">Facilities</h4>
                        <div className="row ps-2">
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value="Wifi"
                              checked={
                                facilities.includes("Wifi") ? true : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox1"
                            >
                              Wifi
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox2"
                              value="TV"
                              checked={facilities.includes("TV") ? true : false}
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox2"
                            >
                              TV
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox3"
                              value="Kitchen"
                              checked={
                                facilities?.includes("Kitchen") ? true : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox3"
                            >
                              Kitchen
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox4"
                              value="Washer"
                              checked={
                                facilities.includes("Washer") ? true : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox4"
                            >
                              Washer
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox5"
                              value="Free Parking"
                              checked={
                                facilities.includes("Free Parking")
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox5"
                            >
                              Free Parking
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox6"
                              value="Paid Parking"
                              checked={
                                facilities.includes("Paid Parking")
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox6"
                            >
                              Paid Parking
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox7"
                              value="Air Conditioning"
                              checked={
                                facilities.includes("Air Conditioning")
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox7"
                            >
                              Air Conditioning
                            </label>
                          </div>
                          <div class="form-check form-check-inline col-md-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox8"
                              value="Dedicated Workspace"
                              checked={
                                facilities.includes("Dedicated Workspace")
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="inlineCheckbox8"
                            >
                              Dedicated Workspace
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow my-5">
                  <div className="card-header d-flex justify-content-center">
                    <h2 className="text-black text-center">Host Information</h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 mb-3">
                        <label for="Name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="Email">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="Phone">Phone Number</label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter your Phone Number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <h4 className=" my-3 text-center">House Rules</h4>
                      <div className="form-group col-md-12 mb-5">
                        <Editor
                          editorState={Editors}
                          onEditorStateChange={onEditorStateChange}
                          // onChange={(e) => setEditors(e)}
                        />
                        {/* <textarea
                                 disabled
                                 value={Editors}
                               />  */}
                      </div>
                      <div className="form-group col-md-6 mb-5">
                        <label for="check-out-date">Check-In-Date</label>
                        <input
                          className="form-control px-5"
                          type="text"
                          placeholder="Check-In-Date"
                          value={checkindate}
                          ref={ref}
                          name=""
                          onChange={(e) => setCheckindate(e.target.value)}
                          onFocus={() => (ref.current.type = "date")}
                          onBlur={() => (ref.current.type = "text")}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-5">
                        <label for="check-out-date">Check-Out-Date</label>
                        <input
                          className="form-control px-5"
                          type="text"
                          placeholder="Check-Out-Date"
                          value={checkoutdate}
                          ref={refs}
                          name=""
                          onChange={(e) => setCheckoutdate(e.target.value)}
                          onFocus={() => (refs.current.type = "date")}
                          onBlur={() => (refs.current.type = "text")}
                        />
                      </div>
                      {/* <div className=""></div> */}

                      <div className="col-md-3"></div>
                      <div className="col-md-6 mt-2">
                        <Button
                          className="btn btn-dark text-white w-100"
                          onClick={add}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Edit_listing;
