import React from 'react'
import { Link } from 'react-router-dom'
import Loader from "../asset/images/Flexloader.gif";
import Logo from "../asset/images/logo.png";

export const Pagenotfound = () => {
  return (
    <>
        <section className='bg-dark h-100vh d-flex align-items-center'>
            <div className='container h-75 d-flex align-items-center w-100 justify-content-center 404_img shadow' style={{border: "2px solid gray", borderRadius: "10px"}}>
                <div className="row justify-content-center align-items-center w-100">
                    <div className='col-lg-12 d-flex justify-content-center align-items-center my-2 flex-column'>
                        <img src={Loader} className="mb-3"/>
                        <img src={Logo} className="img-fluid" width={"25%"}/>

                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <h1 className='text-light text-center'>404 Not Found</h1>
                        <Link to="/" className='btn btn-dark border text-light text-center text_404 d-block mx-auto w-25 mt-4'>Go To Home</Link>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
